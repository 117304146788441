import React, { Component } from "react";
import "./ModelViewerBackground.scss";

class ModelViewerBackground extends Component {
  constructor(props) {
    super(props);

    this.colorToImageSource = this.colorToImageSource.bind(this);
    this.switchBackgroundColor = this.switchBackgroundColor.bind(this);
    this.switchBackgroundAnimated = this.switchBackgroundAnimated.bind(this);
  }

  componentDidMount() {
    if (this.props.opacity !== -1) {
      const container = document.getElementById("js-swa3d-model-background-container");
      container.style.opacity = this.props.opacity;
    }

    if (!this.props.onlyLoadInitialImage) {
      const targetBackground = document.getElementById("js-swa3d-model-background--" + this.props.color);
      targetBackground.classList.add("swa3d-model-background--show");
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.onlyLoadInitialImage) {
      return nextProps.opacity !== this.props.opacity;
    }

    return nextProps.color !== this.props.color || nextProps.opacity !== this.props.opacity;
  }

  componentDidUpdate(prevProps) {
    const container = document.getElementById("js-swa3d-model-background-container");
    if (this.props.opacity !== prevProps.opacity) {
      if (this.props.opacity === -1) {
        if (container.hasAttribute("style")) {
          container.removeAttribute("style");
        }
      } else {
        container.style.opacity = this.props.opacity;
      }
    }

    if (!this.props.onlyLoadInitialImage && this.props.color !== prevProps.color) {
      this.switchBackgroundColor(this.props.color, this.props.animate);
    }
  }

  colorToImageSource(color) {
    if (color === "neutral") {
      return window.swaGiftingConfig.assetUrl + "images/bg-model.png";
    }

    return `${window.swaGiftingConfig.assetUrl}images/bg-box-${color}.png`;
  }

  switchBackgroundColor(color, animate) {
    const targetBackground = document.getElementById("js-swa3d-model-background--" + color);
    const currentBackground = document.querySelector(".swa3d-model-background--show");

    if (!animate) {
      currentBackground.classList.remove("swa3d-model-background--show");
      targetBackground.classList.add("swa3d-model-background--show");
      return;
    }

    this.switchBackgroundAnimated(currentBackground, targetBackground, 1000);
  }

  switchBackgroundAnimated(currentBackground, targetBackground, timeout) {
    currentBackground.classList.add("swa3d-model-background--transition");
    currentBackground.classList.remove("swa3d-model-background--show");
    targetBackground.classList.add("swa3d-model-background--transition");

    setTimeout(() => {
      currentBackground.classList.remove("swa3d-model-background--transition");
      targetBackground.classList.add("swa3d-model-background--show");

      const transitionDuration = parseFloat(getComputedStyle(targetBackground).transitionDuration) * 1000;
      setTimeout(() => {
        targetBackground.classList.remove("swa3d-model-background--transition");
      }, transitionDuration);
    }, timeout);
  }

  render() {
    return (
      <>
        <div id={"js-swa3d-model-background-container"} className={"swa3d-model-background-container"}>
          {this.props.onlyLoadInitialImage ? (
            <img
              id={"js-swa3d-model-background--" + this.props.color}
              className={"swa3d-model-background swa3d-model-background--show"}
              src={this.colorToImageSource(this.props.color)}
              alt=""
            />
          ) : (
            <>
              <img
                id={"js-swa3d-model-background--neutral"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("neutral")}
                alt=""
              />
              <img
                id={"js-swa3d-model-background--green"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("green")}
                alt=""
              />
              <img
                id={"js-swa3d-model-background--pink"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("pink")}
                alt=""
              />
              <img
                id={"js-swa3d-model-background--yellow"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("yellow")}
                alt=""
              />
              <img
                id={"js-swa3d-model-background--blue"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("blue")}
                alt=""
              />
              <img
                id={"js-swa3d-model-background--gray"}
                className={"swa3d-model-background"}
                src={this.colorToImageSource("gray")}
                alt=""
              />
            </>
          )}
        </div>
      </>
    );
  }
}

ModelViewerBackground.defaultProps = {
  onlyLoadInitialImage: false,
  color: "neutral",
  opacity: -1,
  animate: false,
};

export default ModelViewerBackground;
