import tinycolor from "./tinycolor-stripped";
import { modelData } from "./Data";

function hexToRgb(hexColor) {
  // remove # from hex code
  if (hexColor.startsWith("#")) {
    hexColor = hexColor.substring(1);
  }

  if (hexColor.length === 3) {
    hexColor = hexColor[0] + hexColor[0] + hexColor[1] + hexColor[1] + hexColor[2] + hexColor[2];
  } else if (hexColor.length !== 6) {
    return [0, 0, 0];
  }

  const hexValues = hexColor.match(/.{1,2}/g);
  const rgbValues = { r: parseInt(hexValues[0], 16), g: parseInt(hexValues[1], 16), b: parseInt(hexValues[2], 16) };

  return rgbValues;
}

function colorEnhancementBox(rgbValues, colorName) {
  // 1 = no change in brightness
  let brightnessFactor = 1;

  // 100 = max saturation, 0 = no change
  let saturationIncrease = 100;

  let hueShiftDegrees = 0;

  switch (colorName) {
    case "green":
      brightnessFactor = 0.5;
      hueShiftDegrees = 10;
      break;
    case "pink":
      brightnessFactor = 0.85;
      break;
    case "yellow":
      brightnessFactor = 0.75;
      break;
    case "blue":
      brightnessFactor = 0.85;
      break;
    case "gray":
      brightnessFactor = 0.85;
      saturationIncrease = 0;
      break;
  }

  let colorAdjusted = tinycolor({
    r: rgbValues.r * brightnessFactor,
    g: rgbValues.g * brightnessFactor,
    b: rgbValues.b * brightnessFactor,
  })
    .saturate(saturationIncrease)
    .spin(hueShiftDegrees)
    .toRgb();

  return [colorAdjusted.r / 255, colorAdjusted.g / 255, colorAdjusted.b / 255];
}

/**
 * colorize box model to color of box-id
 * @param id
 */
export function colorizeBox(modelViewer, id) {
  const allMaterials = modelViewer.model.materials;

  const rgbValues = hexToRgb(modelData.colors[id].color);
  const boxColor = colorEnhancementBox(rgbValues, modelData.colors[id].colorName);

  // search for a material name that contains "box" but not "inner"
  const materialOutside = allMaterials.find(
    (x) => x.name.toLowerCase().indexOf("box") >= 0 && x.name.toLowerCase().indexOf("inside") === -1
  );

  materialOutside.pbrMetallicRoughness.setBaseColorFactor(boxColor);

  // // search for a material name that contains "card"
  // const materialCard = allMaterials.find((x) => x.name.toLowerCase().indexOf("card") >= 0);

  // if (materialCard !== undefined) {
  //   const cardColor = hexToRgb(modelData.colors[id].colorCard);
  //   materialCard.pbrMetallicRoughness.setBaseColorFactor([cardColor.r / 255, cardColor.g / 255, cardColor.b / 255]);
  // }

  // Brighten colors for following material
  boxColor[0] *= 2;
  boxColor[1] *= 2;
  boxColor[2] *= 2;

  // search for a material name that contains "box" AND "inner"
  const materialInside = allMaterials.find(
    (x) => x.name.toLowerCase().indexOf("box") >= 0 && x.name.toLowerCase().indexOf("inside") >= 0
  );

  if (materialInside !== undefined) {
    materialInside.pbrMetallicRoughness.setBaseColorFactor(boxColor);
  }
}

export async function setCamera(modelViewer, orbit, target = "") {
  modelViewer.cameraOrbit = orbit;
  if (target !== "") {
    modelViewer.cameraTarget = target;
  }
  await new Promise((resolve) => requestAnimationFrame(() => resolve()));
}

export function setInterpolationDecay(modelViewer, value) {
  modelViewer.interpolationDecay = value;
}

/**
 * deactivate modelViewer camera controls
 */
export function deactivateCameraControls(modelViewer) {
  modelViewer.cameraControls = false;
}

/**
 * activate modelViewer camera controls
 */
export function activateCameraControls(modelViewer) {
  modelViewer.cameraControls = true;
}

/**
 * start an specified animation and wait for animation duration
 * @param animation
 * @param animationDuration
 * @returns {Promise<Promise<unknown>>}
 */
export const setAnimation = async (modelViewer, animation, animationDuration) => {
  // hack to prevent animation from playing backwards at times
  // yes: wtf?
  modelViewer.animationName = animation;
  modelViewer.currentTime = 0;
  modelViewer.play();
  modelViewer.pause();

  await new Promise((resolve) => requestAnimationFrame(() => resolve()));

  modelViewer.currentTime = 0;
  modelViewer.play();

  // wait for end of animation and pause it
  return new Promise((resolve) => {
    setTimeout(function () {
      modelViewer.pause();
      resolve(animation);
    }, animationDuration);
  });
};

// adjusted from: https://github.com/google/model-viewer/blob/bb82a5b2097b467c7047447327cebc4080e35fcd/packages/modelviewer.dev/src/tester.ts
export async function createPoster() {
  let posterUrl = "";
  const viewer = document.getElementsByTagName("model-viewer")[0];
  await new Promise((resolve) => requestAnimationFrame(() => resolve()));

  URL.revokeObjectURL(posterUrl);
  const blob = await viewer.toBlob({ mimeType: "image/png", idealAspect: true });
  posterUrl = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = posterUrl;
  a.download = "poster.png";
  a.click();
}
