export let ConfiguratorModelConfig = {
  id: "js-swa3d-config-viewer",
  src: window.swaGiftingConfig.assetUrl + "models/cfg_card.glb",
  alt: "A 3d model of the swarovski box.",
  loading: "eager",
  reveal: "manual",
  autoplay: false,
  minCameraOrbit: "-4m -4m -4m",
  maxCameraOrbit: "4m 4m 4m",
  environmentImage:  window.swaGiftingConfig.assetUrl + "skyboxes/cfg_lighting_alt.hdr",
  shadowIntensity: "0",
  animationCrossfadeDuration: "0",
};