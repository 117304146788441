import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Teaser.scss";

class Teaser extends Component {
  render() {
    const button2 = () => {
      if (this.props.data.button2 !== undefined) {
        if (this.props.data.button2.href !== undefined) {
          return (
            <a
              href={this.props.data.button2.href}
              target="_blank"
              rel="noreferrer"
              className={this.props.data.button2.buttonClasses}
            >
              {this.props.texts.button2}
            </a>
          );
        } else if (this.props.onClickButton2 !== undefined) {
          return (
            <div>
              <button onClick={this.props.onClickButton2}>{this.props.texts.button2}</button>
            </div>
          );
        }
      }

      return this.props.otherTeaserHasSecondaryButton ? <div className={"swa3d-teaser-content-placeholder"}></div> : null;
    };

    return (
      <div className={"swa3d-teaser"}>
        <div className={"swa3d-teaser-image-container"}>
          <a href={this.props.data.button1.href} target="_blank" rel="noreferrer">
            <img
              src={window.swaGiftingConfig.assetUrl + "images/teaser/" + this.props.data.image.name}
              alt={this.props.texts.imgAlt}
            />
          </a>
        </div>

        <div className={"swa3d-teaser-content"}>
          <div>
            <div className={"copy"}>{this.props.texts.copy}</div>
          </div>
          <div></div>
          <div>
            <a
              href={this.props.data.button1.href}
              target="_blank"
              rel="noreferrer"
              className={this.props.data.button1.buttonClasses}
            >
              {this.props.texts.button1}
            </a>
          </div>
          {button2()}
        </div>
      </div>
    );
  }
}

Teaser.defaultProps = {
  data: {},
  texts: {},
  otherTeaserHasSecondaryButton: false,
};

Teaser.propTypes = {
  data: PropTypes.object,
  texts: PropTypes.object,
  otherTeaserHasSecondaryButton: PropTypes.bool,
};

export { Teaser as default, Teaser };
