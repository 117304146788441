import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

document.querySelector("#swa3d").classList.add("swa3d");

function render(data) {
  ReactDOM.render(
    <React.StrictMode>
      <App texts={data} />
    </React.StrictMode>,
    document.getElementById("swa3d")
  );
}

function getPath(language) {
  return window.swaGiftingConfig.assetUrl + "texts/texts_" + language + ".json";
}

function readJson(path, errorCallback = undefined) {
  fetch(path)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      render(data);
    })
    .catch(() => {
      if (errorCallback !== undefined) {
        errorCallback();
      }
    });
}

readJson(getPath(window.swaGiftingConfig.language), () => {
  // load default language when there is an error
  readJson(getPath(window.swaGiftingConfig.defaultLanguage));
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
