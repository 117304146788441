import React, { useEffect } from "react";
import "./FlipCard.scss";

let toTextCharacterThreshold;
let messageCharacterThreshold;
let fromTextCharacterThreshold;

function checkTextLines(textElement, classToAdd, lineBreakpoint, characterThreshold) {
    if (!textElement.classList.contains(classToAdd)) {
      const textHeight = textElement.offsetHeight;

      const style = getComputedStyle(textElement);
      let lineHeight = style.lineHeight;
      if (lineHeight.includes("normal") || lineHeight.includes("intial")) {
        lineHeight = 1.2 * parseInt(style.fontSize);
      } else {
        lineHeight = parseInt(lineHeight);
      }

      const lines = Math.round(textHeight / lineHeight);

      if (lines >= lineBreakpoint) {
        characterThreshold = textElement.innerHTML.length;
        textElement.classList.add(classToAdd);
      }
    } else if (textElement.innerHTML.length < characterThreshold) {
      textElement.classList.remove(classToAdd);
    }

    return characterThreshold;
}

function FlipCard(props) {
  useEffect(() => {
    const fromTextElement = document.querySelector(".swa3d-card-text--headline");
    toTextCharacterThreshold = checkTextLines(fromTextElement, "swa3d-card-text--headline-two-lines", 2, toTextCharacterThreshold);

    const messageElement = document.querySelector(".swa3d-card-text--message");
    messageCharacterThreshold = checkTextLines(messageElement, "swa3d-card-text--message-long", 5, messageCharacterThreshold);

    const toTextElement = document.querySelector(".swa3d-card-text--footer");

    if (!toTextElement.classList.contains("swa3d-card-text--footer-long")) {
      const toTextWidth = toTextElement.offsetWidth;

      const textParent = document.querySelector(".swa3d-card-text");
      const textParentWidth = textParent.offsetWidth;

      if (toTextWidth / textParentWidth > 0.6) {
        toTextElement.classList.add("swa3d-card-text--footer-long");
        fromTextCharacterThreshold = toTextElement.innerHTML.length;
      }
    }
    else if (toTextElement.innerHTML.length < fromTextCharacterThreshold) {
      toTextElement.classList.remove("swa3d-card-text--footer-long");
    }

  });

  return (
    <div id={"swa3d-card"} className={"swa3d-card"}>
      <div className={"swa3d-card-inner"}>
        <div className={"swa3d-card-front"}>
          <div className={"swa3d-card-image"}>
            <img className={"swa3d-card-logo"} src={ window.swaGiftingConfig.assetUrl + "logo/swan-white.svg"} alt={""} />
          </div>
        </div>
        <div className={"swa3d-card-back"}>
          <div className={"swa3d-card-image"}>
            <img className={"swa3d-card-logo"} src={ window.swaGiftingConfig.assetUrl + "logo/wordmark-black.svg"} alt={""} />
          </div>
          <div className={"swa3d-card-text"}>
            <span className={"swa3d-card-text--headline h3 " + (props.data.isShowingPlaceholder ? "swa3d-card-text--headline-placeholder" : "")}>{props.data.to}</span>
            <span className="swa3d-card-text--message copy">{props.data.message}</span>
            <span className="swa3d-card-text--footer h3">
              {props.data.from.length > 0 ? "- " + props.data.from + " -" : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

FlipCard.defaultProps = {
  data: {
    to: "",
    from: "",
    message: "",
    isShowingPlaceholder: false,
  }
}

export { FlipCard as default, FlipCard };
