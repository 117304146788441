import React, { Component } from "react";
import "./TeaserContainer.scss";

class TeaserContainer extends Component {
  render() {
    return (
      <section
        id={"swa3d-teaser-container"}
        className={"swa3d-teaser-container " + (this.props.hide ? "swa3d-teaser-container--hide" : "")}
      >
        <div className={"swa3d-teaser-container-header swa3d-text"}>{this.props.headline}</div>
        <div className={"swa3d-teaser-container-teaser"}>{this.props.teaser}</div>
      </section>
    );
  }
}

TeaserContainer.defaultProps = {
  hide: true,
  headline: [],
  teaser: [],
};

export { TeaserContainer };
