import React, { useEffect } from "react";
import { reducer as configuratorReducer, actions } from "./Reducer";
import { dispatchAnimation } from "./AnimationHandling";
import "./Configurator.scss";
import { videoNames, modelData, timeLineData, translatedTexts, teaser1, teaser2, teaser3 } from "../../utils/Data";
import { generateBase64Url, isMobile, shareExperience, validateInputs } from "./helperFunctions";
import { createPoster } from "../../utils/ModelViewerUtils";
import { ConfiguratorModelConfig } from "./ModelConfig";

import Timeline from "../../components/timeline/Timeline";
import { VideoPlayer } from "../../components/videoPlayer/VideoPlayer";
import { IndicatorBar } from "../../components/indicatorBar/IndicatorBar";
import ModelViewerBackground from "../../components/modelViewerBackground/ModelViewerBackground";
import { FlipCard } from "../../components/flipCard/FlipCard";
import { TeaserContainer } from "../../components/teaserContainer/TeaserContainer";
import { Teaser } from "../../components/teaser/Teaser";
import { Slider } from "../../components/slider/Slider";
import { Thumbnails } from "../../components/thumbnails/Thumbnails";
import { getAssetsPath } from "../../utils/Helper";
import classNames from "classnames";

function Configurator() {
  useEffect(() => {
    // leads to firing tracking event for step 1
    dispatch({
      type: actions.STEP1_GET_ACTIVE,
    });

    const appHeight = () => {
      document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    appHeight();

    if (!isMobile()) {
      window.addEventListener("resize", appHeight);
    }

    // Wait for whole site to build
    setTimeout(() => {
      // used for display widths below 1024px
      const mainContent = document.querySelector(".js-swa3d-configurator-content-main");
      document.documentElement.style.setProperty("--content-height", `${mainContent.offsetHeight}px`);

      const container = document.querySelector(".js-swa3d-container");
      const headerSize = window.innerWidth > 1024 ? 60 : 53;

      const y = container.getBoundingClientRect().top + window.pageYOffset - headerSize;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  }, []);

  //set state variables with initial value
  const [state, dispatch] = React.useReducer(configuratorReducer, {
    timeStep1: new Date(),
    timeStep2: null,
    timeStep3: null,
    timePreview: null,
    step1: true, //initial State - Gift is shown
    step2: false, //show "select box"
    step3: false, //show "add a message"
    step4: false, //show "thank you page"
    backgroundColor: "green",
    selectedVideoId: 0,
    modelName: translatedTexts.videos[0].name,
    modelText: translatedTexts.videos[0].text,
    showBox: false,
    selectedBoxId: 0,
    boxName: translatedTexts.boxes[0].name,
    boxText: translatedTexts.boxes[0].text,
    boxColor: modelData.colors[0].colorName,
    timelineStep: 0,
    toLengthError: false,
    toBadWordsError: false,
    fromLengthError: false,
    fromBadWordsError: false,
    mailLengthError: false,
    mailFormatError: false,
    msgLengthMinError: false,
    msgLengthMaxError: false,
    msgBadWordsError: false,
    shareFailedError: false,
    previewFailedError: false,
    msgLength: 240,
    msgFrom: "",
    msgTo: "",
    msgRecipient: "",
    msgMessage: "",
    indicators: videoNames.length,
    activeIndicator: 0,
    showCard: false,
    flipCard: false,
    showTeasers: false,
    uiLock: false,
  });

  //get variables from state
  const {
    step1,
    step2,
    step3,
    step4,
    backgroundColor,
    modelName,
    modelText,
    boxName,
    boxText,
    selectedVideoId,
    showBox,
    selectedBoxId,
    toLengthError,
    toBadWordsError,
    fromLengthError,
    fromBadWordsError,
    mailLengthError,
    mailFormatError,
    msgLengthMinError,
    msgLengthMaxError,
    msgBadWordsError,
    shareFailedError,
    previewFailedError,
    msgLength,
    msgFrom,
    msgTo,
    msgRecipient,
    msgMessage,
    indicators,
    activeIndicator,
    showCard,
    flipCard,
    showTeasers,
    uiLock,
  } = state;

  const onVideoSliderChange = (nextIndex) => {
    if (nextIndex === selectedVideoId) return;

    dispatch({
      type: actions.CHANGE_SELECTED_VIDEO,
      payload: {
        videoId: nextIndex,
      },
    });
  };

  //create some helper variables
  let fromErrorClass = fromBadWordsError || fromLengthError ? "swa3d-error" : "";
  let toErrorClass = toBadWordsError || toLengthError ? "swa3d-error" : "";
  let msgErrorClass = msgBadWordsError || msgLengthMinError || msgLengthMaxError ? "swa3d-error" : "";
  let mailLengthErrorClass = mailLengthError ? "swa3d-error" : "";

  const showIndicatorBar = step2;
  const showArrows = step2;

  const cameraOrbit = modelData.configuratorAnimationStep[0].cameraOrbit;
  const cameraTarget = modelData.configuratorAnimationStep[0].cameraTarget;

  return (
    <div className={"swa3d-container swa3d-configurator-container js-swa3d-container"} >
      <div className={classNames("swa3d-configurator", {"swa3d-configurator--lock": uiLock, "swa3d-configurator--hide": step4})}>
        {/* <button className={"button button-primary"} style={{ width: "400px", height: "150px" }} onClick={createPoster}>Download poster, disable for production!</button> */}
        <div className={"swa3d-configurator-timeline"}>
          <Timeline data={timeLineData} texts={translatedTexts.steps} activeStep={state.timelineStep} />
        </div>
        <div className={classNames("js-swa3d-configurator-content-main", "swa3d-configurator-main-content",
            {'swa3d-configurator-main-content--fixed-height': isMobile()})}>
          <div className={"swa3d-configurator-video-container"}>
            <div className={"swa3d-configurator-video " + (!step1 ? "swa3d-configurator-video--hide" : "")}>
              <Slider
                  theme={"dark"}
                  onSlideChange={onVideoSliderChange}
                  disableSwipe={true}
                  activeSlide={selectedVideoId}
              >
                {videoNames.map((video, index) => {
                  return (
                      <VideoPlayer
                          videoName={video}
                          videoFormat={"HOR"}
                          stop={!step1 || selectedVideoId !== index}
                          key={index}
                      />
                  );
                })}
              </Slider>
              <Thumbnails onThumbsChange={onVideoSliderChange} activeIndex={selectedVideoId}>
                {videoNames.map((video, index) => {
                  return (
                      <img
                          key={index}
                          src={getAssetsPath() + "images/thumbnails/" + video.replace("FORMAT", "HOR") + ".jpg"}
                          alt="Thumbnail of video"
                      />
                  );
                })}
              </Thumbnails>
            </div>
          </div>
          <div className={"swa3d-configurator-model " + (showBox ? "swa3d-configurator-model--show" : "")}>
            <model-viewer
                // Disable blue outline
                data-js-focus-visible
                id={ConfiguratorModelConfig.id}
                src={ConfiguratorModelConfig.src}
                poster={window.swaGiftingConfig.assetUrl + "poster/card-min-" + selectedBoxId + ".png"}
                alt={ConfiguratorModelConfig.alt}
                loading={ConfiguratorModelConfig.loading}
                reveal={ConfiguratorModelConfig.reveal}
                environment-image={ConfiguratorModelConfig.environmentImage}
                shadow-intensity={ConfiguratorModelConfig.shadowIntensity}
                max-camera-orbit={ConfiguratorModelConfig.maxCameraOrbit}
                min-camera-orbit={ConfiguratorModelConfig.minCameraOrbit}
                animation-crossfade-duration={ConfiguratorModelConfig.animationCrossfadeDuration}
                camera-orbit={cameraOrbit}
                camera-target={cameraTarget}
            >
              {/* Overrides default progress bar of model viewer */}
              <div slot="progress-bar"></div>
            </model-viewer>
            <ModelViewerBackground onlyLoadInitialImage={false} color={backgroundColor} animate={false}/>
            {showArrows && (
                <div className={"swa3d-configurator-arrows"}>
                  <div
                      id={"js-swa3d-arrowPrev"}
                      className={"swa3d-configurator-arrow swa3d-configurator-arrow--left"}
                      onClick={() =>
                          dispatch({
                            type: actions.ARROW_DECREMENT,
                            payload: {
                              step1: step1,
                              step2: step2,
                              selectedVideoId: selectedVideoId,
                              selectedBoxId: selectedBoxId,
                              uiLock: uiLock,
                            },
                          })
                      }
                  >
                    <img src={window.swaGiftingConfig.assetUrl + "icons/arrow_left.svg"} alt={""}/>
                  </div>
                  <div
                      id={"js-swa3d-arrowNext"}
                      className={"swa3d-configurator-arrow swa3d-configurator-arrow--right"}
                      src={window.swaGiftingConfig.assetUrl + "icons/arrow_right.svg"}
                      alt={""}
                      onClick={() =>
                          dispatch({
                            type: actions.ARROW_INCREMENT,
                            payload: {
                              step1: step1,
                              step2: step2,
                              selectedVideoId: selectedVideoId,
                              selectedBoxId: selectedBoxId,
                              uiLock: uiLock,
                            },
                          })
                      }
                  >
                    <img src={window.swaGiftingConfig.assetUrl + "icons/arrow_right.svg"} alt={""}/>
                  </div>
                </div>
            )}
            {showIndicatorBar && (
                <div className={"swa3d-configurator-model-indicator"}>
                  <IndicatorBar itemsCount={indicators} active={activeIndicator}/>
                </div>
            )}
          </div>
          <div
            id={"js-swa3d-config-flipcard"}
            className={`swa3d-configurator-flipcard ${showCard ? "" : "swa3d-configurator-flipcard--hide"} ${
              flipCard ? "swa3d-card-flip" : ""
            }`}
          >
            <VideoPlayer
                videoName={videoNames[selectedVideoId]}
                videoFormat={"HOR"}
            />
            <FlipCard
              data={{
                message: msgMessage,
                from: msgFrom,
                to: msgTo.length > 0 ? msgTo : translatedTexts.steps[2].placeholderReceiverCard,
                isShowingPlaceholder: msgTo.length === 0,
              }}
            />
          </div>
        </div>
        <div className={"swa3d-configurator-text-column"}>
          {step1 && (
            <section id={"js-swa3d-section-jewellery-select"} className={"swa3d-section"}>
              <h2>{modelName}</h2>
              <div className={"swa3d-configurator-info-text copy"}>{modelText}</div>
              <button
                className={"button button--primary"}
                tabIndex={"2"}
                onClick={() => {
                  dispatch({
                    type: actions.STEP1_GET_INACTIVE,
                    payload: {
                      selectedBoxId: selectedBoxId,
                    },
                  });
                  dispatchAnimation(dispatch, {
                    type: actions.STEP1_INCREMENT,
                    payload: {
                      selectedVideoId: selectedVideoId,
                      selectedBoxId: selectedBoxId,
                      uiLock: uiLock,
                    },
                  }).then(() => {
                    dispatch({
                      type: actions.STEP2_GET_ACTIVE,
                    });
                  });
                }}
              >
                {translatedTexts.steps[0].button1Text}
              </button>
            </section>
          )}
          {step2 && (
            <section id={"js-swa3d-section-box-select"} className={"swa3d-section"}>
              <h2>{boxName}</h2>
              <div className={"swa3d-configurator-info-text copy"}>{boxText}</div>
              <div className={"swa3d-configurator-model-indicator-mobile"}>
                <IndicatorBar itemsCount={indicators} active={activeIndicator} />
              </div>
              <button
                className={"button button--primary"}
                tabIndex={"2"}
                onClick={() => {
                  dispatch({
                    type: actions.STEP2_GET_INACTIVE,
                    payload: {
                      selectedVideoId,
                    },
                  });
                  dispatchAnimation(dispatch, {
                    type: actions.STEP2_INCREMENT,
                    payload: {
                      selectedVideoId,
                      selectedBoxId,
                      showCard,
                      uiLock,
                    },
                  }).then(() => {
                    dispatch({
                      type: actions.STEP3_GET_ACTIVE,
                    });
                  });
                }}
              >
                {translatedTexts.steps[1].button1Text}
              </button>
              <button
                className={"button button--tertiary"}
                tabIndex={"3"}
                onClick={() => {
                  dispatch({
                    type: actions.STEP2_GET_INACTIVE,
                  });
                  dispatchAnimation(dispatch, {
                    type: actions.STEP2_DECREMENT,
                    payload: {
                      selectedBoxId: selectedBoxId,
                      selectedVideoId: selectedVideoId,
                      uiLock: uiLock,
                    },
                  }).then(() => {
                    dispatch({
                      type: actions.STEP1_GET_ACTIVE,
                    });
                  });
                }}
              >
                {translatedTexts.steps[1].button2Text}
              </button>
            </section>
          )}
          {step3 && (
            <section id={"js-swa3d-section-add-message"} className={"swa3d-section"}>
              <h2 className="swa3d-configurator-headline-step3">{translatedTexts.steps[2].headline}</h2>
              <div className={"swa3d-configurator-info-text copy"}>{translatedTexts.steps[2].subline}</div>
              <form name={"swa3dform"}>
                <div className={"swa3dform-to-from-container"}>
                  <div className={"swa3d-input-container"}>
                    <input
                      id={"js-swa3d-to"}
                      tabIndex={"2"}
                      type={"input"}
                      className={"copy " + toErrorClass}
                      value={msgTo}
                      placeholder={" "}
                      maxLength={34}
                      onChange={(e) => {
                        dispatch({
                          type: actions.UPDATE_TO_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                      onBlur={(e) => {
                        dispatch({
                          type: actions.CHECK_TO_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                    />
                    <label htmlFor="js-swa3d-to">{translatedTexts.steps[2].placeholderReceiver}</label>
                    {toLengthError && (
                      <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorToLength}</div>
                    )}
                    {toBadWordsError && (
                      <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorBadWord}</div>
                    )}
                  </div>
                  <div className={"swa3d-input-container"}>
                    <input
                      id={"js-swa3d-from"}
                      tabIndex={"3"}
                      type={"input"}
                      className={"copy " + fromErrorClass}
                      value={msgFrom}
                      placeholder={" "}
                      maxLength={28}
                      onChange={(e) => {
                        dispatch({
                          type: actions.UPDATE_FROM_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                      onBlur={(e) => {
                        dispatch({
                          type: actions.CHECK_FROM_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                    />
                    <label htmlFor="js-swa3d-from">{translatedTexts.steps[2].placeholderSender}</label>
                    {fromLengthError && (
                      <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorFromLength}</div>
                    )}
                    {fromBadWordsError && (
                      <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorBadWord}</div>
                    )}
                  </div>
                </div>
                {!navigator.share && (
                  <div className={"swa3d-input-container"}>
                    <input
                      id={"js-swa3d-recipient"}
                      tabIndex={"4"}
                      type={"input"}
                      className={"copy " + mailLengthErrorClass}
                      value={msgRecipient}
                      placeholder={" "}
                      onChange={(e) => {
                        dispatch({
                          type: actions.UPDATE_MAIL_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                      onBlur={(e) => {
                        dispatch({
                          type: actions.CHECK_MAIL_INPUT,
                          payload: { value: e.target.value },
                        });
                      }}
                    />
                    <label htmlFor="js-swa3d-recipient">{translatedTexts.steps[2].placeholderEmail}</label>
                  </div>
                )}
                {!navigator.share && mailLengthError && (
                  <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorMailLength}</div>
                )}
                {!navigator.share && mailFormatError && (
                  <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorMailFormat}</div>
                )}
                <textarea
                  id={"js-swa3d-message"}
                  name={"message"}
                  tabIndex={"5"}
                  placeholder={translatedTexts.steps[2].placeholderMessage}
                  className={"copy " + msgErrorClass}
                  rows={"3"}
                  maxLength={"240"}
                  value={msgMessage}
                  onChange={(e) => {
                    dispatch({
                      type: actions.UPDATE_MSG_INPUT,
                      payload: { value: e.target.value },
                    });
                  }}
                  onBlur={(e) => {
                    dispatch({
                      type: actions.CHECK_MSG_INPUT,
                      payload: { value: e.target.value },
                    });
                  }}
                />
                {msgLengthMinError && (
                  <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorMsgMinLength}</div>
                )}
                {msgLengthMaxError && (
                  <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorMsgMaxLength}</div>
                )}
                {msgBadWordsError && (
                  <div className={"swa3d-error-message"}>{translatedTexts.steps[2].errorBadWord}</div>
                )}
                <span className={"h4"}>
                  {translatedTexts.steps[2].charactersLeft}
                  <span
                    id={"js-swa3d-form-char-left"}
                    className={msgLengthMinError || msgLengthMaxError ? "swa3d-error" : ""}
                  >
                    {msgLength}
                  </span>
                </span>
              </form>
              <button
                id={"swa3d-btn-share"}
                tabIndex={"6"}
                className={"button button--primary"}
                onClick={() => {
                  validateInputs(msgFrom, msgTo, msgMessage, msgRecipient)
                    // is valid
                    .then(() => {
                      // Scroll back to top on mobile
                      if (window.innerWidth < 1025) {
                        const container = document.querySelector(".swa3d-configurator");
                        const y = container.getBoundingClientRect().top + window.pageYOffset - 53;
                        window.scrollTo({ top: y, behavior: "smooth" });
                      }

                      dispatchAnimation(dispatch, {
                        type: actions.STEP_SHARE_TO_FRIEND,
                        payload: {
                          msgFrom,
                          msgTo,
                          msgMessage,
                          msgRecipient,
                          selectedBoxId,
                          selectedVideoId,
                          showCard: false,
                          uiLock,
                        },
                      });
                    })

                    // is invalid
                    .catch((errorStates) => {
                      // Show error messages
                      dispatch({
                        type: actions.STEP3_SHARING_ERROR,
                        payload: errorStates,
                      });
                    })

                    // validation complete
                    .then(() => {
                      // Enable buttons again
                      dispatch({
                        type: actions.UNLOCK_UI_ELEMENTS,
                        payload: {
                          uiLock: false,
                        },
                      });
                    });
                }}
              >
                {translatedTexts.steps[2].button1Text}
              </button>
              {shareFailedError && (
                <div className={"swa3d-error-message"}>{translatedTexts.steps[2].shareFailedError}</div>
              )}
              <button
                className={"button button--secondary"}
                tabIndex={"7"}
                onClick={() => {
                  validateInputs(msgFrom, msgTo, msgMessage, msgRecipient)
                    // is valid
                    .then(() => {
                      // build url params
                      const urlParams = generateBase64Url(
                        msgFrom,
                        msgTo,
                        msgMessage,
                        selectedBoxId,
                        selectedVideoId,
                        true
                      );

                      // Show preview
                      window.open(urlParams);

                      dispatch({
                        type: actions.STEP3_SHOW_PREVIEW,
                      });
                    })

                    // is invalid
                    .catch((errorStates) => {
                      // Show error messages
                      dispatch({
                        type: actions.STEP3_SHOW_PREVIEW_ERROR,
                        payload: errorStates,
                      });
                    })

                    // validation complete
                    .then(() => {
                      // Enable buttons again
                      dispatch({
                        type: actions.UNLOCK_UI_ELEMENTS,
                        payload: {
                          uiLock: false,
                        },
                      });
                    });
                }}
              >
                {translatedTexts.steps[2].button2Text}
              </button>
              {previewFailedError && (
                <div className={"swa3d-error-message"}>{translatedTexts.steps[2].previewFailedError}</div>
              )}
              <button
                className={"button button--tertiary"}
                tabIndex={"8"}
                onClick={() => {
                  // Scroll back to top on mobile
                  if (isMobile()) {
                    const container = document.querySelector(".swa3d-configurator");
                    const y = container.getBoundingClientRect().top + window.pageYOffset - 53;
                    window.scrollTo({ top: y, behavior: "smooth" });
                  }

                  dispatch({
                    type: actions.STEP3_GET_INACTIVE,
                  });
                  dispatchAnimation(dispatch, {
                    type: actions.STEP3_DECREMENT,
                    payload: {
                      msgFrom,
                      msgTo,
                      msgMessage,
                      msgRecipient,
                      selectedVideoId,
                      selectedBoxId,
                      uiLock,
                    },
                  }).then(() => {
                    dispatch({
                      type: actions.STEP2_GET_ACTIVE,
                    });
                  });
                }}
              >
                {translatedTexts.steps[2].button3Text}
              </button>
            </section>
          )}
        </div>
      </div>
      {step4 && (
        <TeaserContainer
          hide={!showTeasers}
          headline={
            <>
              {translatedTexts.steps[3].headline1} {state.msgFrom}, <br />
              {translatedTexts.steps[3].headline2} {msgTo}.
            </>
          }
          teaser={[
            <Teaser
              key={0}
              data={teaser1}
              texts={translatedTexts.teaser[0]}
              onClickButton2={() =>
                shareExperience(msgFrom, msgTo, msgMessage, msgRecipient, selectedBoxId, selectedVideoId)
              }
            />,
            <Teaser key={1} data={teaser2} texts={translatedTexts.teaser[1]} otherTeaserHasSecondaryButton={true} />,
            <Teaser key={2} data={teaser3} texts={translatedTexts.teaser[2]} otherTeaserHasSecondaryButton={true} />,
          ]}
        />
      )}
    </div>
  );
}

export default Configurator;
