import { videoNames, timeLineData, translatedTexts } from "../../utils/Data";
import {
  colorizeBox,
  setCamera,
  setInterpolationDecay,
  deactivateCameraControls,
  activateCameraControls,
} from "../../utils/ModelViewerUtils";

import { getAssetsPath } from "../../utils/Helper";

import Filter from "bad-words";
import badWords_de from "./data/badWords_de.json";
import badWords_es from "./data/badWords_es.json";
import badWords_fr from "./data/badWords_fr.json";
import badWords_it from "./data/badWords_it.json";

//let urlCrypt = require('url-crypt')('~{ry*I)==yU/]9<7DPk!Hj"R#:-/Z7(hTBnlRS=4CXF');

function getFilter() {
  if (window.swaGiftingConfig.language === "de") {
    // german bad word list contain english words
    // so instantiate filter without any words
    let filter = new Filter({ emptyList: true });
    filter.addWords(...badWords_de.badWords);
    return filter;
  }

  let filter = new Filter();
  switch (window.swaGiftingConfig.language) {
    case "es":
      filter.addWords(...badWords_es.badWords);
      break;
    case "fr":
      filter.addWords(...badWords_fr.badWords);
      break;
    case "it":
      filter.addWords(...badWords_it.badWords);
      break;
    default:
      break;
  }

  return filter;
}

const filter = getFilter();

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/*
 * function set timeline to next oder preview page
 * @param id
 * @param action
 * @returns {number}
 */
export function setTimelineStatus(id, action) {
  let tempId;
  //look for next or prev ID
  if (action === "inc") {
    tempId = id + 1;
    if (tempId === timeLineData.length) {
      tempId = 0;
    }
  } else if (action === "dec") {
    tempId = id - 1;
    if (tempId < 0) {
      tempId = timeLineData.length - 1;
    }
  }
  //Delete all active-class information
  timeLineData[id].addClassStep = "";
  timeLineData[tempId].addClassStep = "swa3d-timeline-item--active";

  timeLineData[id].addClassStepName = "";
  timeLineData[tempId].addClassStepName = "swa3d-timeline-item--stepName--active";

  return tempId;
}

export function getModelViewer() {
  return document.querySelector("#js-swa3d-config-viewer");
}

/**
 * switch camera target and camera orbit of model to values of next step
 *
 * @param id
 * @param src
 * @param configStep
 */
export async function changeModelViewerConfig(src, configStep) {
  await setCamera(
    getModelViewer(),
    src.configuratorAnimationStep[configStep].cameraOrbit,
    src.configuratorAnimationStep[configStep].cameraTarget
  );
}
/**
 * super hacky fix to stop animation when going from step 2 to 1
 * and switching jewellery back and forth
 */
export function stopAnimationBug() {
  const modelViewer = getModelViewer();

  setTimeout(() => {
    modelViewer.pause();

    // Skip to end of animation
    modelViewer.currentTime = 10000;
  }, 100);

  modelViewer.removeEventListener("load", stopAnimationBug);
}

/**
 * switch model of gift oder box
 *
 * @param id
 * @param action
 * @param src
 * @param texts
 * @param configStep
 * @param type
 * @returns {{color: *, name: *, back: *, tempId: *, text: *, front: *}}
 */
export function switchModelConfiguration(id, action, src, texts, configStep, type) {
  const modelViewer = getModelViewer();

  let tempId;
  if (action === "inc") {
    tempId = (id + 1) % src.length;
  } else if (action === "dec") {
    tempId = id - 1;
    if (tempId < 0) {
      tempId = src.length - 1;
    }
  }

  let name = "";
  let text = "";
  let front = "";
  let back = "";

  if (type === "model") {
    modelViewer.addEventListener("load", stopAnimationBug);

    deactivateCameraControls(modelViewer);

    const modelComponent = modelViewer.shadowRoot.querySelector(".userInput");
    modelComponent.style.opacity = 0;

    const posterComponent = modelViewer.shadowRoot.querySelector(".poster");
    posterComponent.querySelector("#default-poster").style.backgroundImage = `url(${
      window.swaGiftingConfig.assetUrl + "poster/configurator/" + src[tempId].poster
    })`;

    posterComponent.style.transitionDuration = "0s";
    posterComponent.style.transitionDelay = "0s";
    posterComponent.style.opacity = 1;

    setInterpolationDecay(modelViewer, 0);
    changeModelViewerConfig(src, configStep).then(() => {
      function callback() {
        // safety timeout to prevent flickering on mobile
        setTimeout(() => {
          setInterpolationDecay(modelViewer, 50);
          modelViewer.removeEventListener("load", callback);

          modelComponent.style.opacity = 1;
          posterComponent.style.transitionDuration = "0.2s";
          posterComponent.style.opacity = 0;

          setTimeout(() => {
            activateCameraControls(modelViewer);
          }, 200);
        }, 100);
      }

      modelViewer.addEventListener("load", callback);
      modelViewer.src = window.swaGiftingConfig.assetUrl + "models/" + src[tempId].glb;
    });

    //set texts for info-layer
    name = texts.models[tempId].name;
    text = texts.models[tempId].text;
  } else if (type === "box") {
    colorizeBox(modelViewer, tempId);
    name = texts.boxes[tempId].name;
    text = texts.boxes[tempId].text;
    front = src[tempId].front;
    back = src[tempId].back;
  }

  let color = src[tempId].colorString;

  //return data to state
  return {
    tempId,
    name,
    text,
    color,
    front,
    back,
  };
}

export function shareExperience(msgFrom, msgTo, msgMessage, msgRecipient, boxId, giftId, cancelCallback) {
  let shareUrl = generateBase64Url(msgFrom, msgTo, msgMessage, boxId, giftId);

  if (navigator.share) {
    nativeShareDialog(msgFrom, msgTo, giftId, shareUrl, cancelCallback);
  } else {
    sharePerMail(msgFrom, msgTo, msgRecipient, shareUrl);
  }
}

async function nativeShareDialog(msgFrom, msgTo, giftId, url, cancelCallback) {
  // const image = await fetch(
  //   getAssetsPath() + "images/thumbnails/" + videoNames[giftId].replace("FORMAT", "HOR") + ".jpg"
  // );

  // const blob = await image.blob();

  // const filesArray = [
  //   new File([blob], "thumbnail.jpg", {
  //     type: "image/jpeg",
  //   }),
  // ];

  const shareData = {
    title: translatedTexts.sharing.dialogTitle.replace("REPLACE", msgFrom),
    text: translatedTexts.sharing.dialogText.replace("REPLACE", msgTo),
    url: url,
    // files: filesArray,
  };

  // if (!navigator.canShare(shareData)) {
  //   delete shareData.files;
  // }

  navigator
    .share(shareData)
    .then(() => {})
    .catch((error) => {
      if (cancelCallback !== undefined) {
        cancelCallback();
      }
    });
}

function sharePerMail(msgFrom, msgTo, recipientMail, shareUrl) {
  let subject = encodeURIComponent(translatedTexts.sharing.mailSubject.replace("REPLACE", msgFrom));
  let body = encodeURIComponent(translatedTexts.sharing.mailText.replace("REPLACE", msgTo) + "\r\n" + shareUrl);
  recipientMail = encodeURIComponent(recipientMail);

  window.open(`mailto:${recipientMail}?subject=${subject}&body=${body}`);
}

/**
 * check all input for length and badWord Errors
 * @param from
 * @param to
 * @param msg
 * @param mail
 * @returns {{retToLengthError: *, retFromLengthError: *, retMsgLengthMaxError: *, retMsgBadWordError: *, retFromBadWordError: *, retToBadWordError: *, retMsgLengthMinError: *, retDataOk: *, retRecipientLengthError: *}}
 */
export function checkAllInputs(from, to, msg, mail) {
  const retFromLengthError = checkIsEmpty(from);
  const retToLengthError = checkIsEmpty(to);
  const retRecipientLengthError = navigator.share ? false : checkIsEmpty(mail);
  const retMailFormatError = navigator.share ? false : validateEmail(mail);
  const retMsgLengthMinError = checkIsEmpty(msg);
  const retMsgLengthMaxError = checkMaxMsgLength(msg).lengthError;
  const retFromBadWordError = hasBadWord(from);
  const retToBadWordError = hasBadWord(to);
  const retMsgBadWordError = hasBadWord(msg);
  const retDataOk =
    !retFromLengthError &&
    !retToLengthError &&
    !retRecipientLengthError &&
    !retMailFormatError &&
    !retMsgLengthMinError &&
    !retMsgLengthMaxError &&
    !retFromBadWordError &&
    !retToBadWordError &&
    !retMsgBadWordError;

  const data = {
    retDataOk,
    retFromLengthError,
    retToLengthError,
    retMsgLengthMinError,
    retMsgLengthMaxError,
    retFromBadWordError,
    retToBadWordError,
    retMsgBadWordError,
    retRecipientLengthError,
    retMailFormatError,
  };
  return data;
}

export function validateInputs(from, to, msg, mail) {
  const inputStates = checkAllInputs(from, to, msg, mail);
  if (inputStates.retDataOk) {
    return Promise.resolve();
  }

  return Promise.reject({
    toLengthError: inputStates.retToLengthError,
    toBadWordsError: inputStates.retToBadWordError,
    fromLengthError: inputStates.retFromLengthError,
    fromBadWordsError: inputStates.retFromBadWordError,
    mailLengthError: inputStates.retRecipientLengthError,
    // only show format error if mail field is not empty
    mailFormatError: inputStates.retMailFormatError && !inputStates.retRecipientLengthError,
    msgLengthMinError: inputStates.retMsgLengthMinError,
    msgLengthMaxError: inputStates.retMsgLengthMaxError,
    msgBadWordsError: inputStates.retMsgBadWordError,
  });
}

/**
 * check specified input for length and badWord
 * @param type
 * @param value
 * @returns {{fromLengthError: *, fromBadWordError: *}|{mailLengthError: *}|{msgBadWordError: *, msgLengthMinError: *, msgLength: *, msgLengthMaxError: *}|null|{toBadWordError: *, toLengthError: *}}
 */
export function checkInput(type, value) {
  let data;
  switch (type) {
    case "msg":
      data = checkMaxMsgLength(value);
      return {
        msgLengthMinError: checkIsEmpty(value),
        msgLengthMaxError: data.lengthError,
        msgBadWordError: hasBadWord(value),
        msgLength: data.length,
      };
    case "to":
      return {
        toLengthError: checkIsEmpty(value),
        toBadWordError: hasBadWord(value),
      };
    case "from":
      return {
        fromLengthError: checkIsEmpty(value),
        fromBadWordError: hasBadWord(value),
      };
    case "mail":
      return {
        mailLengthError: checkIsEmpty(value),
        mailFormatError: validateEmail(value),
      };
    default:
      return null;
  }
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let test = re.test(String(email).toLowerCase());
  return !test;
}

/**
 * check if input hast content
 *
 * @param value
 * @returns {boolean}
 */
export function checkIsEmpty(value) {
  if (value !== undefined) {
    return value.length === 0;
  }
  return true;
}

/**
 * check for badWord
 *
 * @param value
 * @returns {boolean}
 */
export function hasBadWord(value) {
  // regex checks if at least one character is in the string
  if (value === undefined || value === "" || value.length < 3 || !/[a-z]/i.test(value)) {
    return false;
  }
  let cleaned = filter.clean(value);
  if (cleaned !== value) {
    return true;
  }
  return false;
}

/**
 * check for max Length (textarea)
 *
 * @param value
 * @returns {{lengthError: boolean, length: number}}
 */
export function checkMaxMsgLength(value) {
  const max = 240;
  return {
    length: max - value.length,
    lengthError: value.length > max,
  };
}

// from: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
function stringToHash(string) {
  let hash = 0;
  if (string.length === 0) {
    return hash;
  }
  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

function getMessageCheckSum(message) {
  let checkSum = 0;
  checkSum += message.split("a").length - 1;
  checkSum += message.split("e").length - 1;
  checkSum += message.split("i").length - 1;
  checkSum += message.split("o").length - 1;
  checkSum += message.split("u").length - 1;
  checkSum *= message.length;
  checkSum *= stringToHash(message);

  return checkSum;
}

/**
 * generate base64 url for sharing
 *
 * @param from
 * @param to
 * @param message
 * @param box
 * @param gift
 * @returns {string}
 */
export function generateBase64Url(from, to, message, box, gift, isPreview = false) {
  const checkSum = getMessageCheckSum(message);
  const base64 = encodeParams(from, to, message, checkSum, isPreview);
  //urlCrypt.cryptObj(parameter);
  const params = `box=${box}&gift=${gift}&q=${base64}`;
  return window.swaGiftingConfig.configuratorUrl + "?" + params + "#/gift/";
}

/**
 * Encodes params
 * @param from {string}
 * @param to {string}
 * @param message {string}
 * @return {string}
 */
export function encodeParams(from, to, message, checkSum, isPreview) {
  let parameter = {
    preview: isPreview ? encodeURIComponent("preview") : "",
    from: encodeURIComponent(from),
    to: encodeURIComponent(to),
    message: encodeURIComponent(message),
    checkSum: encodeURIComponent(checkSum),
  };
  const json = JSON.stringify(parameter);
  const encoded = encodeURIComponent(json);
  const unescaped = unescape(encoded);
  return Buffer.from(unescaped, "binary").toString("base64");
}

/**
 * Decodes params
 * @param encodedString
 * @return {{from: string, to: string, message: string}}
 */
export function decodeParams(encodedString) {
  let preview, from, to, message, checkSum;
  try {
    const json = Buffer.from(encodedString, "base64").toString("binary");
    ({ preview, from, to, message, checkSum } = JSON.parse(json));
  } catch (e) {
    return {
      error: true,
    };
  }

  const messageDecoded = decodeURIComponent(message);

  return {
    preview: decodeURIComponent(preview),
    from: decodeURIComponent(from),
    to: decodeURIComponent(to),
    message: messageDecoded,
    checkSumUrl: parseInt(decodeURIComponent(checkSum)),
    checkSumActual: getMessageCheckSum(messageDecoded),
    error: false,
  };
}

/**
 * function tests values and show preview page, if data are ok
 *
 * @param msgFrom
 * @param msgTo
 * @param msgMessage
 * @param msgRecipient
 * @param animationName
 * @param animationDuration
 * @param boxId
 * @param giftId
 * @returns {Promise<void>}
 */
export const showPreview = function (
  msgFrom,
  msgTo,
  msgMessage,
  msgRecipient,
  animationName,
  animationDuration,
  boxId,
  giftId
) {
  let data = checkAllInputs(msgFrom, msgTo, msgMessage, msgRecipient);
  if (data.retDataOk) {
    window.open(generateBase64Url(msgFrom, msgTo, msgMessage, boxId, giftId), "_blank");
    return data;
  }
  return data;
};
