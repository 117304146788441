export function isMobile() {
  return /Android|iPhone|iPad|iPod|BlackBerry|Opera Mini/i.test(navigator.userAgent);
}

export function getModelContainer() {
  return document.querySelector(".js-swa3d-gift-model");
}

export function getModelViewer() {
  return document.querySelector("#js-swa3d-modelViewer-gift");
}

export function toggleCover() {
  const cover = document.getElementById("js-swa3d-gift-cover");
  cover.classList.toggle("swa3d-gift-cover--show");
}

export function toggleModel() {
  getModelContainer().classList.toggle("swa3d-gift-model--hide");
}

export function toggleVideo() {
  const video = document.querySelector(".swa3d-gift-video-player");
  video.classList.toggle("swa3d-gift-video-player--hide");
}

function toggleSections() {
  const sections = document.querySelector(".js-swa3d-gift-sections");
  sections.classList.toggle("swa3d-gift-sections--hide");

  const spacer = document.querySelector(".swa3d-gift-spacer-desktop");
  spacer.classList.toggle("swa3d-gift-spacer--hide");
}

export function toggleStartScreen() {
  toggleSections();
  const section = document.querySelector('#js-gift-start');
  section.classList.toggle("swa3d-gift-section--hide")
}

export function toggleTimeoutScreen() {
  toggleSections();

  const timeoutScreen = document.getElementById("js-gift-timeout");
  timeoutScreen.classList.toggle("swa3d-gift-section--hide");
}

export function cardMoveIn() {
  // Skip animation to end, when the box is opened
  // getModelViewer().currentTime = 6;

  toggleCover();

  setTimeout(() => {
    const cover = document.getElementById("js-swa3d-gift-card");
    cover.classList.remove("swa3d-gift--hide");
    cover.classList.add("swa3d-gift-card--show");

    setTimeout(() => {
      flipCardToFront();
    }, 1500);
  }, 100);
}

export function cardMoveOut() {
  flipCardToBack();

  setTimeout(() => {
    const card = document.getElementById("js-swa3d-gift-card");
    card.classList.add("swa3d-gift-card--hide");
    card.classList.remove("swa3d-gift-card--show");

    setTimeout(() => {
      card.classList.add("swa3d-gift--hide");
    }, 1000);
  }, 1000);
}

function flipCardToFront() {
  const card = document.getElementById("swa3d-card");
  card.classList.add("swa3d-card-flip");

  setTimeout(() => {
    const cover = document.getElementById("js-swa3d-gift-cover");
    cover.classList.add("swa3d-gift-cover--interaction");
  }, 500);
}

function flipCardToBack() {
  const card = document.getElementById("swa3d-card");
  card.classList.remove("swa3d-card-flip");

  const cover = document.getElementById("js-swa3d-gift-cover");
  cover.classList.remove("swa3d-gift-cover--interaction");
}