
export function mod(n,m) {
    return n - (m * Math.floor(n/m));
    return ((n % m) + m) % m;
}

export function between(x, min, max) {
    return x >= min && x <= max;
}

export function getAssetsPath() {
    return window.swaGiftingConfig.assetUrl;
}

export function getVideoUrl() {
    return window.swaGiftingConfig.videoUrl;
}