import React, { Component } from "react";
import "./Gift.scss";
import { videoNames, modelData, translatedTexts, teaser1, teaser2, teaser3 } from "../../utils/Data";
import { decodeParams } from "../../pages/configurator/helperFunctions";
import { createPoster, colorizeBox } from "../../utils/ModelViewerUtils";
import {
  isMobile,
  getModelContainer,
  getModelViewer,
  toggleCover,
  toggleModel,
  toggleVideo,
  toggleStartScreen,
  toggleTimeoutScreen,
  cardMoveIn,
  cardMoveOut,
} from "./HelperFunctions";

import { VideoPlayer } from "../../components/videoPlayer/VideoPlayer";
import ModelViewerBackground from "../../components/modelViewerBackground/ModelViewerBackground";
import { FlipCard } from "../../components/flipCard/FlipCard";
import { TeaserContainer } from "../../components/teaserContainer/TeaserContainer";
import { Teaser } from "../../components/teaser/Teaser";

class Gift extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      isMobile: false,
      isIos: false,
      displayTeaser: false,
      showTeaser: false,
    };

    const urlParams = new URLSearchParams(window.location.search);
    const decodedParams = decodeParams(urlParams.get("q"));

    //parse data from url
    this.box = urlParams.get("box") || 0;
    this.gift = urlParams.get("gift") || 0;
    this.preview = decodedParams.preview;
    this.from = decodedParams.from;
    this.to = decodedParams.to;
    this.message = decodedParams.message;
    this.checkSumUrl = decodedParams.checkSumUrl;
    this.checkSumActual = decodedParams.checkSumActual;
    this.error = decodedParams.error || this.box >= modelData.colors.length || this.gift >= videoNames.length;

    if (this.preview === "preview") {
      this.state = { isPreview: true };
    }

    if (this.error || this.checkSumUrl !== this.checkSumActual) {
      this.modelSource = window.swaGiftingConfig.assetUrl + "models/gift_404_box.glb";
      this.poster = window.swaGiftingConfig.assetUrl + "poster/404-min.png";
      this.backgroundColor = "error";
      this.cameraOrbit = "35deg 70deg 0.6m";
      this.cameraTarget = "0.033m -0.02m 0m";
      this.state = { error: true };
      return;
    }

    // Setup model config
    const modelFile = "cfg_card.glb";
    const color = modelData.colors[this.box].colorName;

    const modelSource = window.swaGiftingConfig.assetUrl + "models/" + modelFile;

    const posterWithoutExtension = modelData.poster.replace(".png", "");
    const posterName = `${posterWithoutExtension}-${this.box}.png`;
    const poster = window.swaGiftingConfig.assetUrl + "poster/" + posterName;

    const allSteps = modelData.configuratorAnimationStep;
    const animationStep = allSteps.find((x) => x.step === "gift");

    this.modelSource = modelSource;
    this.poster = poster;
    this.backgroundColor = color;
    this.cameraOrbit = animationStep.cameraOrbit;
    this.cameraTarget = animationStep.cameraTarget;
    this.animationName = animationStep.animationName;
    this.animationDuration = animationStep.animationDuration;

    this.onUnpackButtonClick = this.onUnpackButtonClick.bind(this);
    this.onCoverClick = this.onCoverClick.bind(this);

    this.videoCallback = () => {};
  }

  componentDidMount() {
    const modelViewer = getModelViewer();

    modelViewer.addEventListener("load", () => {
      if (!this.state.error) {
        colorizeBox(modelViewer, this.box);
      }

      modelViewer.pause();
    });

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    appHeight();

    // do not recalculate
    // window.addEventListener('resize', appHeight)

    // Wait for whole site to build
    setTimeout(() => {
      this.setState({ isMobile: isMobile(), isIos: /iPhone|iPad|iPod/i.test(navigator.userAgent) });

      const container = document.querySelector(".swa3d-gift");
      const headerSize = window.innerWidth > 1024 ? 60 : 53;

      const y = container.getBoundingClientRect().top + window.pageYOffset - headerSize;
      window.scrollTo({ top: y, behavior: "smooth" });
    }, 500);
  }

  onUnpackButtonClick() {
    toggleStartScreen();
    toggleVideo();
    toggleModel();

    // Play animation
    const modelViewer = getModelViewer();
    modelViewer.play();

    setTimeout(() => {
      modelViewer.pause();
    }, this.animationDuration);

    setTimeout(() => {
      cardMoveIn();

      // set state to let gift check headline font size
      this.setState({
        flipCardData: {
          from: this.from,
          to: this.to,
          message: this.message,
          isShowingPlaceholder: false,
        },
      });
    }, 4000);
  }

  onCoverClick() {
    cardMoveOut();

    if (!this.state.isPreview) {
      this.setState({ displayTeaser: true });
    }

    setTimeout(() => {
      toggleCover();

      setTimeout(() => {
        if (!this.state.isPreview) {
          this.setState({ showTeaser: true });
        } else {
          toggleTimeoutScreen();
        }
      }, 1500);
    }, 500);
  }

  render() {
    const renderSection = () => {
      if (this.state.error) {
        return (
          <section id="js-gift-start" className="swa3d-gift-section swa3d-gift-start">
            <h2 className="swa3d-content-state1__title">{translatedTexts.gift.error.headline}</h2>
            {/* <p className="swa3d-content-state1__copy copy">{this.from + translatedTexts.gift.start.copy}</p> */}
            <button
              type="button"
              className="swa-btn swa-btn-primary swa-btn--full js-start-unboxing button button--primary"
              onClick={() => {
                window.open(window.location.origin, "_self");
              }}
            >
              <span className="swa-btn__text">{translatedTexts.gift.error.button}</span>
            </button>
          </section>
        );
      } else {
        let copy = translatedTexts.gift.start.copy;

        if (copy.indexOf("REPLACE") !== -1) {
          copy = copy.replace("REPLACE", this.from);
        } else {
          copy = this.from + copy;
        }

        return (
          <>
            <section id="js-gift-start" className="swa3d-gift-section swa3d-gift-start">
              <h2 className="swa3d-content-state1__title">{this.to}</h2>
              <p className="swa3d-content-state1__copy copy">{copy}</p>
              <button
                type="button"
                id="startUnboxing"
                className="swa-btn swa-btn-primary swa-btn--full js-start-unboxing button button--primary"
                // data-text-content="{Unbox it}"
                onClick={this.onUnpackButtonClick}
              >
                <span className="swa-btn__text">{translatedTexts.gift.start.button}</span>
              </button>
            </section>
            <section id="js-gift-timeout" className="swa3d-gift-section swa3d-gift-section--hide swa3d-gift-timeout">
              <h2 className="swa3d-content-state1__title">
                {this.state.isPreview
                  ? translatedTexts.gift.endPreview.headline
                  : translatedTexts.gift.end.headline + this.to}
              </h2>
              <p className="swa3d-content-state1__copy">
                {this.state.isPreview ? translatedTexts.gift.endPreview.copy : translatedTexts.gift.end.copy}
              </p>
              <div className={"swa3d-gift-section-buttons"}>
                <button
                  type="button"
                  className="swa3d button button--primary"
                  onClick={
                    this.state.isPreview
                      ? () => {
                          window.close();
                        }
                      : () => {
                          window.location.href = window.location.origin;
                        }
                  }
                >
                  <span className="swa-btn__text">
                    {this.state.isPreview ? translatedTexts.gift.endPreview.button : translatedTexts.gift.end.button}
                  </span>
                </button>
              </div>
            </section>
          </>
        );
      }
    };
    return (
      <>
        <div className={"swa3d-container"}>
          <div id="js-swa3d-gift" className="swa3d-gift">
            {/* <button className={"button button-primary"} style={{ width: "400px", height: "150px" }} onClick={createPoster}>Download poster, disable for production!</button> */}
            <div className="swa3d-gift-content">
              <div className="js-swa3d-gift-content-main swa3d-gift-content-main">
                <div
                  className={
                    "js-swa3d-gift-model swa3d-gift-model " + (this.state.error ? "" : "swa3d-gift-model--hide")
                  }
                >
                  <model-viewer
                    // Disable blue outlines
                    data-js-focus-visible
                    id={"js-swa3d-modelViewer-gift"}
                    src={this.modelSource}
                    alt={"Gift presentation"}
                    poster={this.poster}
                    // Autoplay to jump to beginning of animation on load
                    autoplay
                    animation-name={this.animationName}
                    environment-image={window.swaGiftingConfig.assetUrl + "skyboxes/cfg_lighting_alt.hdr"}
                    shadow-intensity={"0"}
                    camera-orbit={this.cameraOrbit}
                    camera-target={this.cameraTarget}
                    max-camera-orbit={"4m 4m 4m"}
                    min-camera-orbit={"-4m -4m -4m"}
                  >
                    <div slot="ar-button" className={"js-swa3d-modelviewer-arbutton"}></div>
                    {/* Overrides default progress bar of model viewer */}
                    {this.state.error && <div slot="progress-bar"></div>}
                  </model-viewer>
                  <ModelViewerBackground
                    onlyLoadInitialImage={true}
                    color={this.backgroundColor}
                    opacity={-1}
                    animate={false}
                  />
                </div>
                {!this.state.error && (
                  <div className="swa3d-gift-video-player">
                    {this.state.isPreview && translatedTexts.gift.start.preview && (
                      <p className={"copy text-center"} style={{ marginBottom: 16 }}>
                        {translatedTexts.gift.start.preview.replace("REPLACE", this.to)}
                      </p>
                    )}
                    <VideoPlayer
                      sizeGivingContainerSelector=".js-swa3d-gift-content-main"
                      videoName={videoNames[this.gift]}
                      videoFormat={"HOR"}
                    />
                  </div>
                )}
              </div>
              <div className={"swa3d-gift-spacer-desktop"}></div>
              <div className={"js-swa3d-gift-sections swa3d-gift-sections"}>{renderSection()}</div>
            </div>
            <div className={"swa3d-gift-cover"} id={"js-swa3d-gift-cover"} onClick={this.onCoverClick}></div>
            <div id={"js-swa3d-gift-card"} className="swa3d-gift-card swa3d-gift--hide">
              <FlipCard data={this.state.flipCardData} />
            </div>
          </div>
          {this.state.displayTeaser && (
            <TeaserContainer
              hide={!this.state.showTeaser}
              headline={translatedTexts.gift.end.headline + this.to}
              teaser={[
                <Teaser
                  key={0}
                  data={teaser1}
                  texts={{
                    copy: translatedTexts.gift.end.copy,
                    button1: translatedTexts.gift.end.button,
                    imgAlt: "",
                  }}
                />,
                <Teaser key={1} data={teaser2} texts={translatedTexts.teaser[1]} />,
                <Teaser key={2} data={teaser3} texts={translatedTexts.teaser[2]} />,
              ]}
            />
          )}
        </div>
      </>
    );
  }
}

export default Gift;
