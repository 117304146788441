import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./swa3d.scss";
import Gift from "./pages/gift/Gift";
import Configurator from "./pages/configurator/Configurator";
import { setTexts } from "./utils/Data";

function App(props) {
  setTexts(props.texts);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="*/gift/" component={Gift} />
          <Route exact path="*/" component={Configurator} />
        </Switch>
      </Router>
    </>
  );
}

export default App;