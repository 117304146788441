import React, { Component } from "react";
import "./Thumbnails.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

class Thumbnails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: props.activeIndex
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidUpdate(prevProps ) {
        if(this.props.activeIndex !== prevProps.activeIndex && this.props.activeIndex !== this.state.activeIndex) {
            this.changeToThumb(this.props.activeIndex);
        }
    }

    handleClick(index) {
        this.changeToThumb(index)
        if(this.props.onThumbsChange) this.props.onThumbsChange(index);
    }

    changeToThumb(index) {
        this.setState({
            activeIndex : index
        })
    }

    render() {
        return (
            <div className={classNames('swa3d-thumbnails')}>
                <div className={classNames('swa3d-thumbnails__container')}>
                {this.props.children.map((child,index)  => {
                    return (
                        <div
                            className={classNames('swa3d-thumbnails__thumb', {'swa3d-thumbnails__thumb--active': this.state.activeIndex === index})}
                            key={index}
                            onClick={() => {this.handleClick(index)}}>
                            { child }
                        </div>
                    );
                })}
                </div>
            </div>
        );
    }
}

Thumbnails.defaultProps = {
    activeIndex: 0
}

Thumbnails.propTypes = {
    children: PropTypes.array,
    activeIndex: PropTypes.number,
    onThumbsChange: PropTypes.func
}

export {Thumbnails}