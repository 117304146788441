import React, { Component } from "react";
import "./Slider.scss";
import PropTypes from "prop-types";
import { between, mod } from "../../utils/Helper";
import classNames from "classnames";

class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSlide: props.activeSlide
        }

        for (const index in props.children) {
            this[`slide${index}`] = React.createRef();
        }

        this.handleSlideChange = this.handleSlideChange.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps ) {
        if(this.props.activeSlide !== prevProps.activeSlide && this.props.activeSlide !== this.state.activeSlide) {
            this.changeToSlide(this.props.activeSlide)
        }
    }

    handleResize() {
        const currentElement = this[`slide${this.state.activeSlide}`].current;
        if (currentElement) {
            currentElement.scrollIntoView({block: 'nearest'});
        }
    }

    handleSlideChange(nextSlide){
        this.setState( {
            activeSlide: nextSlide
        });
        if(this.props.onSlideChange) this.props.onSlideChange(nextSlide);
    }

    handleScroll(e) {
        const scrollElement   = e.target;
        const atSnappingPoint = between(scrollElement.scrollLeft % e.target.offsetWidth, 0, 0);
        const timeOut         = atSnappingPoint ? 0 : 200;

        clearTimeout(scrollElement.scrollTimeout);
        scrollElement.scrollTimeout = setTimeout(() => {
            const scrollElementLeft = scrollElement.getBoundingClientRect().left
            for (let index in this.props.children) {
                index = parseInt(index);
                const currentElement = this[`slide${index}`].current
                if (currentElement) {
                    if (between(currentElement.getBoundingClientRect().left - scrollElementLeft, -1, 1)) {
                        if(this.state.activeSlide !== index) this.handleSlideChange(index);
                        break;
                    }
                }
            }
        }, timeOut);
    }

    changeToSlide(index) {
        index = mod(index, this.props.children.length);
        const currentElement = this[`slide${index}`].current;

        // scrollIntoView trigger this.handleScroll() function
        if(currentElement) currentElement.scrollIntoView({ block: 'nearest' });
    }

    render() {
        return (
            <div className={classNames('swa3d-slider', `swa3d-slider--${this.props.theme}`)}>
                <div className={classNames('swa3d-slider__container', {'swa3d-slider__container--swipe-disabled': this.props.disableSwipe})} onScroll={this.handleScroll}>
                {this.props.children.map((child,index)  => {
                    return (
                        <div className={'swa3d-slider__slide'} key={index} ref={this[`slide${index}`]}>
                            { child }
                        </div>
                    );
                })}
                </div>
                <div className={'swa3d-slider__btn swa3d-slider__btn--prev'} onClick={() => this.changeToSlide(this.state.activeSlide - 1)}><img src={window.swaGiftingConfig.assetUrl + "icons/arrow_left.svg"} alt={""} /></div>
                <div className={'swa3d-slider__btn swa3d-slider__btn--next'} onClick={() => this.changeToSlide(this.state.activeSlide + 1)}><img src={window.swaGiftingConfig.assetUrl + "icons/arrow_right.svg"} alt={""} /></div>
            </div>
        )
    }
}

Slider.defaultProps = {
    activeSlide: 0,
    theme: 'light',
    children: [],
    disableSwipe: false
}

Slider.propTypes = {
    activeSlide: PropTypes.number,
    theme: PropTypes.oneOf(['light', 'dark']),
    children: PropTypes.array,
    onSlideChange: PropTypes.func,
    disableSwipe: PropTypes.bool,
}

export {Slider}