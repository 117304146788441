import React, { Component } from "react";
import "./VideoPlayer.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import {getAssetsPath, getVideoUrl} from "../../utils/Helper";

class VideoPlayer extends Component {

  constructor(props) {
    super(props);

    this.videoRef = React.createRef();

    this.state = {
      hidePlayButton: false,
      hidePoster: false,
      triggerChange: false,
      videoFormat: props.videoFormat || this.getVideoFormat()
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleLoadedVideo = this.handleLoadedVideo.bind(this);
    this.handleVideoEnd = this.handleVideoEnd.bind(this);
    this.handleVideoPause = this.handleVideoPause.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    if (!this.props.videoFormat && this.props.sizeGivingContainerSelector) {
      window.addEventListener("resize", this.handleResize);
      setTimeout(this.handleResize, 10)
    }
  }

  componentDidUpdate(prevProps) {
    const player = this.videoRef.current;
    const stopVideo = this.props.stop === true && prevProps.stop !== true;

    if (stopVideo && player) {
      player.pause();
      player.currentTime = 0;
      player.removeAttribute("controls");

      this.setState({
        hidePoster: false,
        hidePlayButton: false
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getVideoFormat() {
    let format = "HOR";

    if (window.innerWidth < 1024) {
      const mainContent = document.querySelector(this.props.sizeGivingContainerSelector);

      if (mainContent) {
        const videoAspectRatios = [16 / 9 /*, 9 / 16 */, 1 / 1, 4 / 5];

        let smallestDifference = Number.MAX_VALUE;
        let videoSourceIndex;

        for (let i = 0; i < videoAspectRatios.length; i++) {
          const videoHeight = mainContent.offsetWidth / videoAspectRatios[i];
          const heightDifference = mainContent.offsetHeight - videoHeight;
          if (heightDifference > 0 && heightDifference < smallestDifference) {
            smallestDifference = heightDifference;
            videoSourceIndex = i;
          }
        }

        switch (videoSourceIndex) {
            // case 1:
            //   format = "VER";
            //   break;
          case 1:
            format = "SQ";
            break;
          case 2:
            format = "POR";
            break;
          default:
            format = "HOR";
            break;
        }
      }
    }

    return format;
  }


  getNameWithFormat(name) {
    return name.replace("FORMAT", this.state.videoFormat)
  }

  handleResize() {
    if(this.state.videoFormat !== this.getVideoFormat()) this.setState({videoFormat: this.getVideoFormat()})
  }

  handleClick() {
    this.setState({
      hidePoster: true,
      hidePlayButton: true
    })

    const player = this.videoRef.current;

    if(player) {
      player.play();
      player.setAttribute("controls", "");
    }
  }

  handleLoadedVideo() {
    this.setState({
      videoIsLoaded: true
    })
  }

  handleVideoEnd() {
    this.setState({
      hidePoster: false,
      hidePlayButton: false
    })

    if( this.props.onEndCallback ) {this.props.onEndCallback()}
  }

  handleVideoPause() {
    if( this.props.onPauseCallback ) {this.props.onPauseCallback()}
  }

  render() {
    return (
        <div className={"js-swa3d-video swa3d-video"}>
          <video
              className={"js-swa3d-video-player"}
              controlsList="nodownload"
              name={"media"}
              preload={"auto"}
              playsInline
              src={getVideoUrl() + this.getNameWithFormat(this.props.videoName) + ".mp4"}
              ref={this.videoRef}
              onEnded={this.handleVideoEnd}
              onPause={this.handleVideoPause}
          />
          <img
              className={classNames("swa3d-video-poster", {"hideOut": this.state.hidePoster})}
              src={getAssetsPath() + "images/thumbnails/" + this.getNameWithFormat(this.props.videoName) + ".jpg"}
              alt="Thumbnail of video"
          />
          <div className={classNames("swa3d-video-button__wrapper", {"hideOut": this.state.hidePlayButton})}>
            <div className="swa3d-video-button"
                 onClick={this.handleClick}>
              <img src={getAssetsPath() + "icons/button-video-play.svg"} alt={""}/>
            </div>
            <div className={"swa3d-video-button__bg"}/>
          </div>
        </div>
    );
  }
}

VideoPlayer.defaultProps = {
  videoName: "",
  stop: false,
};

VideoPlayer.propTypes = {
  sizeGivingContainerSelector: PropTypes.string,
  videoName: PropTypes.string.isRequired,
  onPauseCallback: PropTypes.func,
  onEndCallback: PropTypes.func,
  stop: PropTypes.bool
}

export { VideoPlayer };
