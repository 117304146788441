import React, {Component} from 'react';
import './IndicatorBar.scss'


class IndicatorBar extends Component {
	render() {
		const items = [];
		for (let i = 0; i < this.props.itemsCount; i++) {
			if(i === this.props.active){
				items.push(<div key={i} className={"swa3d-indicator-bar-child swa3d-indicator-bar-child--active"}> </div>);
			} else {
				items.push(<div key={i} className={"swa3d-indicator-bar-child"}> </div>);
			}
		}

		return (
			<div className={"swa3d-indicator-bar"}>
				{items}
			</div>
		)
	}
}

IndicatorBar.defaultProps = {
		itemsCount: 3,
		active: 0
};

export {
	IndicatorBar as default,
	IndicatorBar
}
