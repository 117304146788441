import React, { Component, Fragment } from "react";
import { translatedTexts } from "../../utils/Data";
import "./Timeline.scss";

class Timeline extends Component {
  render() {
    const itemList = Object.keys(this.props.data);
    return (
      <>
        <section className="swa3d-timeline">
          <div className={"swa3d-timeline-row"}>
            {itemList.map((item, id) => (
              <Fragment key={"number_" + id}>
                <div className={"swa3d-timeline-item"}>
                  <div className={"swa3d-timeline-item--step " + this.props.data[item].addClassStep}>
                    {this.props.data[item].stepNr}
                  </div>
                </div>
                {!this.props.data[item].lastChild && <hr />}
              </Fragment>
            ))}
          </div>
          <div className={"swa3d-timeline-row swa3d-timeline-row-bottom"}>
            {itemList.map((item, key) => (
              <div className={"swa3d-timeline-item-text"} key={"text_" + key}>
                <div className={"swa3d-timeline-item--stepName " + this.props.data[item].addClassStepName}>
                  {this.props.texts[item].stepName}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className={"swa3d-timeline-mobile"}>
          <div className={"swa3d-timeline-row--number"}>
            {translatedTexts.step} {this.props.data[this.props.activeStep].stepNr} {translatedTexts.of}{" "}
            {this.props.data.length}
          </div>
          <div className={"swa3d-timeline-row--name"}>{this.props.texts[this.props.activeStep].stepName}</div>
        </section>
      </>
    );
  }
}

Timeline.defaultProps = {
  data: {
    0: {
      stepNr: 1,
      stepName: "Choose your product",
      lastChild: false,
      addClassStep: "swa3d-timeline-item--active",
      addClassStepName: "swa3d-timeline-item--stepName--active",
    },
    1: {
      stepNr: 2,
      stepName: "Choose your box",
      lastChild: false,
      addClassStep: "",
      addClassStepName: "",
    },
    2: {
      stepNr: 3,
      stepName: "Send to friend",
      lastChild: true,
      addClassStep: "",
      addClassStepName: "",
    },
  },
  texts: {
    0: {
      stepName: "Select a model",
    },
    1: {
      stepName: "Choose your box",
    },
    2: {
      stepName: "Send to friend",
    },
  },
  activeStep: 0,
};

export { Timeline as default, Timeline };
