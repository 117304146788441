const timeLineData = [
  {
    stepNr: 1,
    stepName: "",
    lastChild: false,
    addClassStep: "swa3d-timeline-item--active",
    addClassStepName: "swa3d-timeline-item--stepName--active",
  },
  {
    stepNr: 2,
    stepName: "",
    lastChild: false,
    addClassStep: "",
    addClassStepName: "",
  },
  {
    stepNr: 3,
    stepName: "",
    lastChild: true,
    addClassStep: "",
    addClassStepName: "",
  },
];

const videoNames = [
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-TREE-TITLE-FORMAT-17SEC-NOVO-VID_GL_25FPS_H64",
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-TABLE-TITLE-FORMAT-14SEC-NOVO-VID_GL_25FPS_H65",
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-GINGERB-TITLE-FORMAT-17SEC-NOVO-VID_GL_25FPS_H66",
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-CHOIR-TITLE-FORMAT-16SEC-NOVO-VID_GL_25FPS_H67",
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-STAGE-TITLE-FORMAT-15SEC-NOVO-VID_GL_25FPS_H68",
  "B2C_BRAND_VIDEO-HOLIDAY-DECO-GIFTS-TITLE-FORMAT-21SEC-NOVO-VID_GL_25FPS_H69",
];

function calcAnimationDuration(blenderFrameAmount, padding = 100) {
  return (blenderFrameAmount * 30 + padding).toString();
}

const modelData = {
  colors: [
    {
      colorName: "green",
      color: "#77BB78",
      colorCard: "#27852a",
    },
    {
      colorName: "pink",
      color: "#F8AAB6",
      colorCard: "#ff4f6d",
    },
    {
      colorName: "yellow",
      color: "#FCCF61",
      colorCard: "#ff8404",
    },
    {
      colorName: "blue",
      color: "#8DBAED",
      colorCard: "#2f66ff",
    },
    // {
    //   colorName: "gray",
    //   color: "#fff",
    // },
  ],
  name: "Gift Card",
  poster: "card-min.png",
  configuratorAnimationStep: [
    {
      step: 0,
      // cameraOrbit: "0deg 70deg 0.1875m",
      cameraOrbit: "0deg 70deg 0.225m",
      cameraTarget: "0m 0.049986m 0m",
    },
    {
      step: 1,
      // cameraOrbit: "0deg 70deg 0.225m",
      // cameraTarget: "0m 0.049986m 0m",
      animationName: "cfg_hse_step02_00",
      animationNameBack: "",
      animationDuration: 1,
      animationDurationBack: 1,
    },
    {
      step: 2,
      // cameraOrbit: "0deg 70deg 0.225m",
      // cameraTarget: "0m 0.049986m 0m",
      animationName: "cfg_hse_step02_00",
      animationNameBack: "cfg_hse_step02_01",
      animationDuration: calcAnimationDuration(110),
      animationDurationBack: calcAnimationDuration(110),
    },
    {
      step: 3,
      // cameraOrbit: "0deg 70deg 0.225m",
      // cameraTarget: "0m 0.049986m 0m",
      animationName: "cfg_hse_step03_00",
      animationNameBack: "",
      animationDuration: calcAnimationDuration(110),
      animationDurationBack: "",
    },
    {
      step: "gift",
      cameraOrbit: "0deg 70deg 0.225m",
      cameraTarget: "0m 0.049986m 0m",
      // cameraOrbit: "0deg 70deg 0.25m",
      // cameraTarget: "0m 0.055m 0m",
      animationName: "cfg_hse_step03_01",
      animationDuration: calcAnimationDuration(160),
    },
  ],
};

const teaser1 = {
  image: {
    name: "143415358-41475d9e-c5ef-492e-99a7-56e6318d5c4d.png",
  },
  button1: {
    href: window.swaGiftingConfig.configuratorUrl,
    buttonClasses: "button button--primary",
  },
  button2: {
    buttonClasses: "button button--tertiary",
  },
};

const teaser2 = {
  image: {
    name: "B2C_BRAND_KV-HOLIDAY-1_OF_GL_300DPI_RGB.tif_GL_TT_SD.jpg",
  },
  button1: {
    href: "https://www.swarovski.com/c-4002/",
    buttonClasses: "button button--secondary",
  },
};

const teaser3 = {
  image: {
    name: "142176266-c392fc2b-e566-4c92-96de-8ecd435e51d9.jpg",
  },
  button1: {
    href: "https://www.swarovski.com/c-040903",
    buttonClasses: "button button--secondary",
  }
}

let translatedTexts;

function setTexts(texts) {
  translatedTexts = texts;
}

// function setLanguage(language) {
//   switch (language) {
//     case "de":
//       translatedTexts = texts.de;
//       break;
//     case "it":
//       translatedTexts = texts.it;
//       break;
//     case "fr":
//       translatedTexts = texts.fr;
//       break;
//     default:
//       translatedTexts = texts.en;
//       break;
//   }
// }

// setLanguage(window.swaGiftingConfig.language);

export { timeLineData, videoNames, modelData, teaser1, teaser2, teaser3, setTexts, translatedTexts };
