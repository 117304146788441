import { videoNames, modelData, translatedTexts } from "../../utils/Data";
import {
  checkMaxMsgLength,
  checkInput,
  switchModelConfiguration,
  setTimelineStatus,
} from "./helperFunctions";

const actions = {
  CHANGE_TIMELINE_STEP: "change-timeline-step",
  SET_ACTIVE_INDICATOR: "set-active-indicator",
  SET_INDICATOR_COUNT: "set-count-indicator",
  TOGGLE_MODEL_VIEWER: "toggle-model-viewer",
  STEP1_INCREMENT: "increment-step1",
  STEP2_DECREMENT: "decrement-step2",
  STEP2_INCREMENT: "increment-step2",
  STEP3_DECREMENT: "decrement-step3",
  STEP3_SHOW_PREVIEW: "show-preview",
  STEP3_SHOW_PREVIEW_ERROR: "show-preview-error",
  STEP_SHARE_TO_FRIEND: "share-to-a-friend",
  STEP3_SHARING_ERROR: "sharing-failed",
  STEP3_SHARING_COMPLETE: "sharing-complete",
  STEP1_GET_ACTIVE: "step1-get-active",
  STEP1_GET_INACTIVE: "step1-get-inactive",
  STEP2_GET_ACTIVE: "step2-get-active",
  STEP2_GET_INACTIVE: "step2-get-inactive",
  STEP3_GET_ACTIVE: "step3-get-active",
  STEP3_GET_INACTIVE: "step3-get-inactive",
  UPDATE_FROM_INPUT: "update-from-input",
  UPDATE_TO_INPUT: "update-to-input",
  UPDATE_MSG_INPUT: "update-msg-input",
  UPDATE_MAIL_INPUT: "update-mail-input",
  CHECK_ALL_INPUTS: "check-all-inputs",
  CHECK_FROM_INPUT: "check-from-input",
  CHECK_TO_INPUT: "check-to-input",
  CHECK_MSG_INPUT: "check-msg-input",
  CHECK_MAIL_INPUT: "check-mail-input",
  CHANGE_SELECTED_VIDEO: "change-selected-video",
  ARROW_INCREMENT: "arrow-increment",
  ARROW_DECREMENT: "arrow-decrement",
  LOCK_UI_ELEMENTS: "lock-ui-elements",
  UNLOCK_UI_ELEMENTS: "unlock-ui-elements",
  SHOW_CARD: "show-card",
  FLIP_CARD: "flip-card",
  HIDE_CARD: "hide-card",
  SHOW_TEASER: "show-teaser",
  CHANGE_BACKGROUND: "change-background",
};

const reducer = (state, action) => {
  let data;
  switch (action.type) {
    case actions.CHANGE_TIMELINE_STEP:
      return {
        ...state,
        timelineStep: setTimelineStatus(state.timelineStep, action.payload.action),
      };

    case actions.SET_ACTIVE_INDICATOR:
      return {
        ...state,
        activeIndicator: action.payload.activeIndicator,
      };

    case actions.SET_INDICATOR_COUNT:
      return {
        ...state,
        indicators: action.payload.indicators,
      };

    case actions.TOGGLE_MODEL_VIEWER:
      return {
        ...state,
        showBox: !state.showBox,
      };

    case actions.STEP1_INCREMENT:
      return {
        ...state,
        activeIndicator: action.payload.selectedBoxId,
        selectedVideoId: action.payload.selectedVideoId,
        indicators: modelData.colors.length,
        uiLock: false,
      };
    case actions.STEP1_GET_ACTIVE:
      // Check for timeStep2 as timeStep1 will always have a value
      // This case will be false if the user comes back from step 2
      if (state.timeStep2 === null) {
        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Step 1",
          eventLabel: "",
          eventNonInteractive: false,
          eventValue: (state.timeStep1 - window.giftingStartTime) / 1000,
        });
      }
      return {
        ...state,
        step1: true,
        indicators: videoNames.length,
      };
    case actions.STEP1_GET_INACTIVE:
      return {
        ...state,
        step1: false,
      };

    case actions.STEP2_GET_ACTIVE:
      let timeStep2;
      if (state.timeStep2 === null) {
        timeStep2 = new Date();
        const seconds = Math.abs(timeStep2 - state.timeStep1) / 1000;

        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Step 2",
          eventLabel: "",
          eventNonInteractive: false,
          eventValue: seconds,
        });
      } else {
        timeStep2 = state.timeStep2;
      }

      return {
        ...state,
        step2: true,
        timeStep2: timeStep2,
      };
    case actions.STEP2_GET_INACTIVE:
      return {
        ...state,
        step2: false,
      };
    case actions.STEP3_GET_ACTIVE:
      let timeStep3;
      if (state.timeStep3 === null) {
        timeStep3 = new Date();
        const seconds = Math.abs(timeStep3 - state.timeStep2) / 1000;

        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Step 3",
          eventLabel: "",
          eventNonInteractive: false,
          eventValue: seconds,
        });
      } else {
        timeStep3 = state.timeStep3;
      }

      return {
        ...state,
        step3: true,
        timeStep3: timeStep3,
      };

    case actions.STEP3_GET_INACTIVE:
      return {
        ...state,
        step3: false,
      };
    case actions.STEP2_DECREMENT:
      return {
        ...state,
        activeIndicator: action.payload.selectedVideoId,
        selectedBoxId: action.payload.selectedBoxId,
        indicators: modelData.length,
        uiLock: false,
      };

    case actions.STEP2_INCREMENT:
      return {
        ...state,
        shareFailedError: false,
        uiLock: false,
      };

    case actions.STEP3_DECREMENT:
      return {
        ...state,
        shareFailedError: false,
        uiLock: false,
      };

    case actions.SHOW_CARD:
      return {
        ...state,
        showCard: true,
      };

    case actions.FLIP_CARD:
      return {
        ...state,
        flipCard: true,
      };

    case actions.HIDE_CARD:
      return {
        ...state,
        showCard: false,
        flipCard: false,
      };

    case actions.CHANGE_BACKGROUND:
      return {
        ...state,
        backgroundColor: action.payload.backgroundColor,
      };

    case actions.CHANGE_SELECTED_VIDEO:
      return {
        ...state,
        selectedVideoId: action.payload.videoId,
        activeIndicator: action.payload.videoId,
        uiLock: false,
      };

    case actions.ARROW_INCREMENT:
      if (action.payload.step1) {
        const videoId = (action.payload.selectedVideoId + 1) % videoNames.length;

        return {
          ...state,
          selectedVideoId: videoId,
          activeIndicator: videoId,
          // modelName: data.name,
          // modelText: data.text,
          uiLock: false,
        };
      } else if (action.payload.step2) {
        data = switchModelConfiguration(
          action.payload.selectedBoxId,
          "inc",
          modelData.colors,
          translatedTexts,
          1,
          "box"
        );
        return {
          ...state,
          backgroundColor: modelData.colors[data.tempId].colorName,
          selectedBoxId: data.tempId,
          boxName: data.name,
          boxText: data.text,
          activeIndicator: data.tempId,
          uiLock: false,
        };
      } else this.preventDefault();
      return state;

    case actions.ARROW_DECREMENT:
      if (action.payload.step1) {
        let videoId = action.payload.selectedVideoId - 1;
        if (videoId < 0) {
          videoId = videoNames.length - 1;
        }

        return {
          ...state,
          selectedVideoId: videoId,
          activeIndicator: videoId,
          // modelName: data.name,
          // modelText: data.text,
          uiLock: false,
        };
      } else if (action.payload.step2) {
        data = switchModelConfiguration(
          action.payload.selectedBoxId,
          "dec",
          modelData.colors,
          translatedTexts,
          1,
          "box"
        );
        return {
          ...state,
          backgroundColor: modelData.colors[data.tempId].colorName,
          selectedBoxId: data.tempId,
          boxName: data.name,
          boxText: data.text,
          activeIndicator: data.tempId,
          uiLock: false,
        };
      } else this.preventDefault();
      return state;

    case actions.UPDATE_FROM_INPUT:
      return {
        ...state,
        msgFrom: action.payload.value,
      };

    case actions.CHECK_FROM_INPUT:
      data = checkInput("from", action.payload.value);
      if (data === null) return state;

      return {
        ...state,
        fromBadWordsError: data.fromBadWordError,
        fromLengthError: data.fromLengthError,
        shareFailedError: false,
      };

    case actions.UPDATE_TO_INPUT:
      return {
        ...state,
        msgTo: action.payload.value,
      };

    case actions.CHECK_TO_INPUT:
      data = checkInput("to", action.payload.value);
      if (data === null) return state;

      return {
        ...state,
        toBadWordsError: data.toBadWordError,
        toLengthError: data.toLengthError,
        shareFailedError: false,
      };

    case actions.UPDATE_MSG_INPUT:
      return {
        ...state,
        msgMessage: action.payload.value,
        msgLength: checkMaxMsgLength(action.payload.value).length,
      };

    case actions.CHECK_MSG_INPUT:
      data = checkInput("msg", action.payload.value);
      if (data === null) return state;
      return {
        ...state,
        msgBadWordsError: data.msgBadWordError,
        msgLengthMinError: data.msgLengthMinError,
        msgLengthMaxError: data.msgLengthMaxError,
        msgMessage: action.payload.value,
        msgLength: data.msgLength,
        shareFailedError: false,
      };

    case actions.UPDATE_MAIL_INPUT:
      return {
        ...state,
        msgRecipient: action.payload.value.replace(/ /g, ""),
      };

    case actions.CHECK_MAIL_INPUT:
      data = checkInput("mail", action.payload.value);
      if (data === null) return state;
      return {
        ...state,
        shareFailedError: false,
        mailLengthError: data.mailLengthError,
        // only show format error if mail field is not empty
        mailFormatError: data.mailFormatError && !data.mailLengthError,
      };

    case actions.STEP3_SHOW_PREVIEW:
      let timePreview;
      if (state.timePreview === null) {
        timePreview = new Date();
        const seconds = Math.abs(timePreview - state.timeStep3) / 1000;

        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Preview",
          eventLabel: "",
          eventNonInteractive: false,
          eventValue: seconds,
        });
      } else {
        timePreview = state.timePreview;
      }

      return {
        ...state,
        timePreview: timePreview,
      };

    case actions.STEP3_SHOW_PREVIEW_ERROR:
      return {
        ...state,
        toLengthError: action.payload.toLengthError,
        toBadWordsError: action.payload.toBadWordsError,
        fromLengthError: action.payload.fromLengthError,
        fromBadWordsError: action.payload.fromBadWordsError,
        mailLengthError: action.payload.mailLengthError,
        mailFormatError: action.payload.mailFormatError,
        msgLengthMinError: action.payload.msgLengthMinError,
        msgLengthMaxError: action.payload.msgLengthMaxError,
        msgBadWordsError: action.payload.msgBadWordsError,
        // previewFailedError: !action.payload.retDataOk,
        shareFailedError: false,
        previewFailedError: false,
      };
    case actions.STEP3_SHARING_ERROR:
      return {
        ...state,
        toLengthError: action.payload.toLengthError,
        toBadWordsError: action.payload.toBadWordsError,
        fromLengthError: action.payload.fromLengthError,
        fromBadWordsError: action.payload.fromBadWordsError,
        mailLengthError: action.payload.mailLengthError,
        mailFormatError: action.payload.mailFormatError,
        msgLengthMinError: action.payload.msgLengthMinError,
        msgLengthMaxError: action.payload.msgLengthMaxError,
        msgBadWordsError: action.payload.msgBadWordsError,
        shareFailedError: false,
        previewFailedError: false,
      };

    case actions.STEP_SHARE_TO_FRIEND:
      if (action.payload.data.retDataOk) {
        let seconds;
        if (state.timePreview !== null) {
          seconds = Math.abs(new Date() - state.timePreview) / 1000;
          // fallback to time of step 3
        } else {
          seconds = Math.abs(new Date() - state.timeStep3) / 1000;
        }

        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Share",
          eventLabel: "",
          eventNonInteractive: false,
          eventValue: seconds,
        });


        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Video Choice Index",
          eventLabel: action.payload.data.selectedVideoId,
          eventNonInteractive: false,
          eventValue: 0,
        });

        const boxColor = modelData.colors[action.payload.data.selectedBoxId].colorName;

        window.dataLayer.push({
          event: "standardEventTracking",
          eventCategory: "AR Gifting",
          eventAction: "Box Choice",
          eventLabel: boxColor,
          eventNonInteractive: false,
          eventValue: 0,
        });

        return {
          ...state,
          step4: true,
          previewFailedError: false,
          shareFailedError: false,
          uiLock: true,
        };
      // Data not ok
      } else {
        return {
          ...state,
          toLengthError: action.payload.data.retToLengthError,
          toBadWordsError: action.payload.data.retToBadWordError,
          fromLengthError: action.payload.data.retFromLengthError,
          fromBadWordsError: action.payload.data.retFromBadWordError,
          mailLengthError: action.payload.data.retRecipientLengthError,
          mailFormatError: action.payload.data.retMailFormatError,
          msgLengthMinError: action.payload.data.retMsgLengthMinError,
          msgLengthMaxError: action.payload.data.retMsgLengthMaxError,
          msgBadWordsError: action.payload.data.retMsgBadWordError,
          shareFailedError: !action.payload.data.retDataOk,
          previewFailedError: false,
          uiLock: false,
        };
      }

    case actions.SHOW_TEASER:
      return {
        ...state,
        showTeasers: true,
      }

    case actions.LOCK_UI_ELEMENTS:
      return {
        ...state,
        uiLock: true,
      };

    case actions.UNLOCK_UI_ELEMENTS:
      return {
        ...state,
        uiLock: false,
      };

    default:
      return state;
  }
};

export { reducer, actions };
