import { actions } from "./Reducer";
import { modelData } from "../../utils/Data";
import { ConfiguratorModelConfig } from "./ModelConfig";
import { getModelViewer, checkAllInputs, shareExperience, isMobile } from "./helperFunctions";
import { colorizeBox, setAnimation } from "../../utils/ModelViewerUtils";

/**
 * function for increment Step1 to Step2
 *
 * @param animationName
 * @param animationDuration
 * @param modelViewerConfig
 * @param selectedModel
 * @param selectedBox
 * @param modelData
 * @param configStep
 * @returns {Promise<void>}
 */
const step1Increment = async function (
  animationName,
  animationDuration,
  modelViewerConfig,
  selectedModel,
  selectedBox,
  modelData,
  configStep
) {
  const modelViewer = getModelViewer();

  function setUpModelViewer(resolve) {
    colorizeBox(modelViewer, selectedBox);

    modelViewer.animationName = animationName;
    modelViewer.play();
    modelViewer.pause();
    resolve();
  }

  await new Promise((resolve) =>
    setTimeout(() => {
      modelViewer.dismissPoster();

      if (!modelViewer.loaded) {
        modelViewer.addEventListener(
          "load",
          () => {
            setUpModelViewer(resolve);
          },
          true
        );
      } else {
        setUpModelViewer(resolve);
      }
      // Wait 500ms because the size transition of the model viewer takes that long
    }, 500)
  );
};

/**
 * function for increment Step2 to Step3
 *
 * @param animationName
 * @param animationDuration
 * @param modelViewerConfig
 * @param selectedModel
 * @param modelData
 * @param configStep
 * @returns {Promise<void>}
 */
const step2Increment = async function (
  animationName,
  animationDuration,
  modelViewerConfig,
  selectedModel,
  modelData,
  configStep
) {
  const modelViewer = getModelViewer();

  // changeModelViewerConfig(modelData, configStep);

  await setAnimation(modelViewer, animationName, animationDuration).then(() => {
    return Promise.resolve();
  });
};

/**
 * function for decrement Step3 to Step2
 *
 * @param animationName
 * @param animationDuration
 * @param modelViewerConfig
 * @param selectedModel
 * @param modelData
 * @param configStep
 * @returns {Promise<void>}
 */
const step3Decrement = async function (
  animationName,
  animationDuration,
  modelViewerConfig,
  selectedModel,
  modelData,
  configStep
) {
  const modelViewer = getModelViewer();

  await setAnimation(modelViewer, animationName, animationDuration).then(() => {
    // changeModelViewerConfig(modelData, configStep);
    return Promise.resolve();
  });
};

const shareAnimation = async function (
  animationName,
  animationDuration,
  msgFrom,
  msgTo,
  msgMessage,
  msgRecipient,
  boxId,
  giftId,
  callback1,
  callback2
) {
  let boxClosed = false;
  let closeBoxAndShowTeaser = () => {
    // prevent this method being called multiple times
    if (boxClosed) {
      return;
    }

    boxClosed = true;

    callback1();
    setAnimation(getModelViewer(), animationName, animationDuration).then(() => {
      callback2();
    });
  };

  shareExperience(msgFrom, msgTo, msgMessage, msgRecipient, boxId, giftId, closeBoxAndShowTeaser);

  // the window does not lose focus on mobile, so we have to instantly play the animation
  if (isMobile()) {
    closeBoxAndShowTeaser();
  }
  else {
    window.addEventListener("focus", closeBoxAndShowTeaser, { once: true });
  }
};

/**
 *
 * @param dispatch function
 * @param action
 * @returns {Promise<void>}
 */
const dispatchAnimation = async function (dispatch, action) {
  // Ignore target payload and lock ui until operation is complete
  dispatch({ type: actions.LOCK_UI_ELEMENTS, payload: { uiLock: true } });
  let promise = Promise.resolve();

  // Animate
  const { type, payload } = action;
  switch (type) {
    case actions.STEP1_INCREMENT:
      dispatch({ type: actions.TOGGLE_MODEL_VIEWER });
      dispatch({
        type: actions.CHANGE_BACKGROUND,
        payload: {
          backgroundColor: modelData.colors[payload.selectedBoxId].colorName,
          backgroundOpacity: -1,
          animateBackground: true,
        },
      });
      dispatch({
        type: actions.CHANGE_TIMELINE_STEP,
        payload: { action: "inc" },
      });
      promise = promise.then(() => {
        return step1Increment(
          modelData.configuratorAnimationStep[1].animationName,
          modelData.configuratorAnimationStep[1].animationDuration,
          ConfiguratorModelConfig,
          payload.selectedVideoId,
          payload.selectedBoxId,
          modelData,
          1
        );
      });
      break;

    case actions.STEP2_DECREMENT:
      dispatch({ type: actions.TOGGLE_MODEL_VIEWER });
      dispatch({
        type: actions.CHANGE_TIMELINE_STEP,
        payload: { action: "dec" },
      });
      promise = promise.then(() => {
        return;
      });
      break;

    case actions.STEP2_INCREMENT:
      dispatch({
        type: actions.CHANGE_TIMELINE_STEP,
        payload: { action: "inc" },
      });

      promise = promise.then(() => {
        return step2Increment(
          modelData.configuratorAnimationStep[2].animationName,
          modelData.configuratorAnimationStep[2].animationDuration,
          ConfiguratorModelConfig,
          payload.selectedVideoId,
          modelData,
          2
        );
      });

      dispatch({
        type: actions.SHOW_CARD,
      });

      setTimeout(() => {
        dispatch({
          type: actions.FLIP_CARD,
        });
      }, 2000);
      break;

    case actions.STEP3_DECREMENT:
      dispatch({
        type: actions.CHANGE_TIMELINE_STEP,
        payload: { action: "dec" },
      });
      dispatch({
        type: actions.HIDE_CARD,
      });
      promise = promise.then(() => {
        return step3Decrement(
          modelData.configuratorAnimationStep[2].animationNameBack,
          modelData.configuratorAnimationStep[2].animationDurationBack,
          ConfiguratorModelConfig,
          payload.selectedVideoId,
          modelData,
          2
        );
      });
      break;

    case actions.STEP_SHARE_TO_FRIEND:
      let data = checkAllInputs(
        action.payload.msgFrom,
        action.payload.msgTo,
        action.payload.msgMessage,
        action.payload.msgRecipient
      );
      data.selectedBoxId = action.payload.selectedBoxId;
      data.selectedVideoId = action.payload.selectedVideoId;

      dispatch({
        type: actions.STEP_SHARE_TO_FRIEND,
        payload: { data: data },
      });

      if (data.retDataOk) {
        return shareAnimation(
          modelData.configuratorAnimationStep[3].animationName,
          modelData.configuratorAnimationStep[3].animationDuration,
          action.payload.msgFrom,
          action.payload.msgTo,
          action.payload.msgMessage,
          action.payload.msgRecipient,
          action.payload.selectedBoxId,
          action.payload.selectedVideoId,
          () =>
            dispatch({
              type: actions.HIDE_CARD,
            }),
          () =>
            dispatch({
              type: actions.SHOW_TEASER,
            })
        );
      }
      break;
    default:
      return Promise.resolve();
  }
  // Update state to unlock ui and set step
  await promise.then(() => {
    payload.uiLock = false;
    dispatch({ type, payload });
  });
};

export { dispatchAnimation };
